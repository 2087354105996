import styled from 'styled-components'
import { motion } from 'framer-motion'

export const Wrapper = styled.div`
    box-sizing: border-box;
    width: 100%;
    color: white;
    position: relative;
    display: flex;
`
export const Background = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: #41d323;
    @media (max-width: 850px) {
        -webkit-clip-path: polygon(0 0, 100% 0, 100% 88%, 0 100%);
        clip-path: polygon(0 0, 100% 0, 100% 88%, 0 100%);
    }
`
export const Inner = styled(motion.div)`
    width: 93%;
    margin: 0px auto;
    max-width: 1600px;
    min-width: 1200px;
    position: relative;
    display: flex;
    justify-content: space-between;
    height: fit-content;
    align-items: center;

    @media (max-width: 1200px) {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        /* padding-inline: 15px; */
    }

    @media (max-width: 850px) {
        flex-direction: column;
        align-items: center;
    }
`

export const BackgroundImg = styled(motion.img)`
    width: 100%;
    margin-inline-end: auto;
    margin-block-end: -5px;
    margin-inline-start: -5vw;

    @media (max-width: 850px) {
        margin-inline-start: -5%;
        margin-block-end: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        min-width: 550px;
    }
`
export const ContentContainer = styled.div`
    position: relative;
    width: 50%;
    min-width: 50%;
    height: fit-content;

    @media (max-width: 850px) {
        width: 380px;
        text-align: center;
        margin-block-start: 35px;
    }
`
export const Content = styled(motion.div)`
    font-weight: 900;
    font-size: 50px;
    width: 68%;
    line-height: 100%;
    position: absolute;
    z-index: 9;
    top: 155px;
    margin-inline-start: 127px;
    color: black;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    white-space: pre-wrap;
    @media (max-width: 1200px) {
        margin-inline-start: 10%;
        font-size: 42px;
    }
    @media (max-width: 850px) {
        margin-inline-start: auto;
        margin-inline-end: auto;
        height: fit-content;
        position: relative;
        width: fit-content;
        top: 0;
    }
`
export const Button = styled(motion.button)`
    border-radius: 35px;
    font-size: 35px;
    font-weight: 900;
    background: ${({ bgcolor }) => bgcolor};
    color: ${({ color }) => color};
    outline: 0;
    border: 0;
    min-width: 350px;
    padding: 11px 25px;
    margin-block-start: 35px;
    margin-inline-start: 1vw;
    cursor: pointer;
    transition: all 0.3s ease-out;
    &:hover {
        background: ${({ color }) => color};
        color: ${({ bgcolor }) => bgcolor};
    }

    @media (max-width: 850px) {
        margin-block-start: 20px;
        padding: 8px 20px;
        font-size: 28px;
        width: fit-content;
        max-width: fit-content;
    }
`
export const ImgsContainer = styled.div`
    position: relative;
    max-height: 94%;
    width: fit-content;
    margin-inline-end: -10%;
    margin-block-end: -9%;
    z-index: 9;
    @media (max-width: 850px) {
        width: 450px;
        max-width: 100%;
        z-index: 999;
        margin-block-start: 20px;
        margin-inline-end: -22%;
    }
`
export const TabletMockup = styled(motion.img)`
    display: block;
    width: 94%;
    margin-bottom: -10%;

    @media (max-width: 100px) {
    }
`
export const PhoneMockup = styled(motion.img)`
    top: 14%;
    width: 44%;
    margin-inline-start: -15%;
    left: 0;
    position: absolute;
`
export const FoodImg = styled(motion.img)`
    top: 24%;
    width: 16%;
    margin-inline-start: 7%;
    left: 0;
    position: absolute;
    @media (max-width: 850px) {
        top: 16%;

        width: 24%;
    }
`

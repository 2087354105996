import styled from 'styled-components'
import { motion } from 'framer-motion'

export const Wrapper = styled.div`
    box-sizing: border-box;
    width: 100%;
    background: rgb(39, 168, 65);
    background: linear-gradient(
        336deg,
        rgba(39, 168, 65, 1) 0%,
        rgba(60, 178, 72, 1) 25%,
        rgba(66, 178, 75, 0.7629618497789741) 100%
    );
    min-height: 500px;
    padding-block-start: 5vw;
    margin-block-end: 12%;
    color: white;
    position: relative;
    @media (max-width: 1200px) {
        min-height: fit-content;
        margin-block-end: 0;
    }
`
export const Inner = styled.div`
    width: 93%;
    margin: 0px auto;
    max-width: 1600px;
    min-width: 1200px;
    height: fit-content;
    position: relative;
    display: flex;
    justify-content: space-between;

    @media (max-width: 1200px) {
        min-width: 100%;
        max-width: 100%;
        padding-inline: 15px;
        box-sizing: border-box;
        flex-direction: column;
        align-items: center;
    }

    @media (max-width: 1200px) {
        justify-content: center;
    }
    @media (max-width: 750px) {
        flex-direction: column;
        align-items: center;
    }
    @media (max-width: 650px) {
        padding-block-end: 50px;
        padding-block-start: 15px;
    }
`
export const ContentContainer = styled.div`
    width: 49%;
    margin-inline-start: -3.5%;
    height: fit-content;
    margin-bottom: -4px;
    position: relative;
    @media (max-width: 1400px) {
        margin-inline-start: -8%;
    }

    @media (max-width: 1200px) {
        position: relative;
        margin-inline: 0 !important;
        width: fit-content;
    }
    @media (max-width: 750px) {
        height: auto;
    }
`
export const WomeImg = styled.img`
    width: 100%;
    @media (max-width: 1200px) {
        position: absolute;
        left: 0;
        width: 45%;
        min-width: 350px;
        bottom: 0;
    }
    @media (max-width: 650px) {
        left: -20%;
    }
    @media (max-width: 480px) {
        left: -35%;
    }
`

export const ImgsContainer = styled.div`
    width: 40%;
    height: 100%;
    margin-inline-end: 4%;
    margin-block-start: 4%;
    position: relative;

    @media (max-width: 1200px) {
        margin-inline-end: -10%;
        margin-inline-start: 0;
        width: 420px;
        margin-block-start: 70px;
    }
    @media (max-width: 750px) {
        min-width: 318px;
        width: 318px;
    }
    @media (max-width: 650px) {
        margin-block-start: 35px;
        margin-inline-end: -10%;
    }
`
export const IphoneImg = styled(motion.img)`
    position: absolute;
    width: 100%;
    @media (max-width: 1200px) {
        position: relative;
    }
`
export const CornImg = styled.img`
    position: absolute;
    width: 42%;
    top: 0;
    margin-block-start: 24%;
    margin-inline-start: 57%;
    @media (max-width: 1200px) {
        right: 0;
    }
`
export const CheeseImg = styled(motion.img)`
    position: absolute;
    width: 59%;
    top: 0;
    margin-inline-start: 46%;
    margin-block-start: -13%;
    @media (max-width: 1200px) {
        right: 0;
    }
`
export const OnionImg = styled.img`
    position: absolute;
    width: 31.5%;
    top: 0;
    margin-block-start: 25%;
    margin-inline-start: -14%;
    @media (max-width: 1200px) {
        left: 0;
    }
`

export const Content = styled.div`
    width: 45%;
    height: 400px;
    top: 0;
    position: absolute;
    font-size: 45px;
    margin-inline-start: 77%;
    margin-block-start: -15%;
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    gap: 5%;
    white-space: pre-wrap;

    & h2 {
        margin: 0;
        font-weight: 900;
        font-size: 52px;
    }
    @media (max-width: 1400px) {
        width: 52%;
        min-width: 285px;
    }
    @media (max-width: 1400px) {
        font-size: 32px;
    }
    @media (max-width: 1370px) {
        margin-block-start: -22%;
    }
    @media (max-width: 1200px) {
        height: auto;
        position: relative;
        margin: 0;
        margin-inline: 0;
        width: fit-content;
        margin-inline-end: 0;
        max-width: 450px;
    }
    @media (max-width: 900px) {
        margin-block-end: 25px;
    }
    @media (max-width: 750px) {
        margin-inline-end: 0;
    }
`

import styled from 'styled-components'

export const Wrapper = styled.div`
    background: #161616;
    position: relative;
    height: 100vh;
    min-height: 800px;
    width: 100%;
    display: flex;
    justify-content: center;
    .textDirection {
        ${({ isRTL }) => isRTL && ` direction:rtl;`}
    }
    @media (max-width: 875px) {
        min-height: 623px;
        height: 100vh;
        /* overflow: hidden; */
        align-items: center;
    }
    @media (max-height: 600px) {
        height: auto;
    }
    @supports (-webkit-touch-callout: none) {
        min-height: 623px;
        height: 100%;
        height: -webkit-fill-available;
    }
`
export const Footer = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    min-height: 40px;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;

    & p {
        width: 90%;
        max-width: 1200px;
        display: flex;
        justify-content: flex-end;
        margin: 0;
        padding: 0;
        padding-inline-end: 10%;
        color: black;
        font-size: 20px;
    }
    @media (max-width: 875px) {
        z-index: 99;
        & p {
            padding: 0;
            justify-content: center;
            text-align: center;
        }
    }
    @media (max-width: 740px) {
        & p {
            font-size: 18px;
        }
    }
`

export const Inner = styled.div`
    width: 90%;
    max-width: 1200px;
    min-height: 100%;
    display: flex;
    @media (max-width: 875px) {
        flex-wrap: wrap;
        min-height: unset;
        height: fit-content;
        flex-direction: column-reverse;
    }
`
export const WomanImg = styled.img`
    height: 87%;
    margin-block-start: auto;
    max-height: 829px;
    position: relative;
    z-index: 9;
    @media (max-width: 1200px) {
        height: 660px;
        margin-left: -5%;
    }
    @media (max-width: 960px) {
        margin-left: -15%;
    }
    @media (max-width: 875px) {
        display: none;
    }
`
export const Content = styled.div`
    min-width: 500px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* background: red; */
    min-height: 100px;
    align-items: center;
    margin-bottom: 40px;
    @media (max-width: 1200px) {
        min-width: 450px;
    }
    @media (max-width: 875px) {
        min-height: unset;
        height: auto;
        flex: unset;
    }
    @media (max-width: 500px) {
        max-width: 100%;
        min-width: 100%;
    }
`
export const Logo = styled.img`
    width: 101px;
    margin-bottom: 20px;

    @media (max-height: 700px) {
        max-width: 75px;
        margin-bottom: 15px;
    }
`
export const ErrorMessage = styled.div`
    color: #721c24;
    background-color: #f8d7da;
    width: 100%;
    height: 30px;
    font-size: 19px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    justify-content: center;
    border: 1px solid #f5c6cb;
`

export const Text = styled.div`
    width: fit-content;
    color: white;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3px;

    & h2 {
        font-size: 42px;
        font-weight: 700;
        margin: 0;
        line-height: 100%;
    }

    & p {
        line-height: 100%;
        font-size: 32px;
        font-weight: 400;
        margin: 0;
        direction: rtl;
    }
    @media (max-height: 700px) {
        & h2 {
            font-size: 30px;
        }
        & p {
            font-size: 22px;
        }
    }
`
export const FormContainer = styled.form`
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-block-start: 20px;
    @media (max-width: 875px) {
        width: 100%;
        max-width: 450px;
        gap: 15px;
    }
    @media (max-width: 500px) {
        max-width: 100%;
    }
`
export const CheckBoxContainer = styled.div`
    display: flex;
    justify-content: center;
    color: white;
    font-size: 22px;
    margin-block: -10px;
    height: fit-content;
    svg {
        fill: white;
    }
    @media (max-width: 740px) {
        min-height: 19px;
    }
`
export const SubmitButton = styled.button`
    width: 100%;
    height: 54px;
    cursor: pointer;
    font-weight: 700;
    border-radius: 35px;
    font-size: 28px;
    background: #2dd10c;
    display: flex;
    justify-content: center;
    color: white;
    align-items: center;
    outline: 0;
    border: 0;
    &:hover {
        background: white;
        color: black;
    }

    @media (max-width: 740px) {
        font-size: 22px;
        height: 40px;
    }
`
export const Terms = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    color: white;
    font-size: 20px;
    gap: 4px;
    text-align: center;
    a {
        color: white;
        text-decoration: none;
        font-weight: 700;
    }

    a:hover {
        color: #2dd10c;
    }
`

export const Applications = styled.div`
    width: 100%;
    max-width: 400px;
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-block-start: 35px;
    @media (max-width: 740px) {
        /* margin-block: auto; */
        margin-block-start: 20px;
    }
`

export const AppImg = styled.img`
    cursor: pointer;
    flex: 1;
    max-width: 50%;
    border: 1px solid rgba(255, 255, 255, 0.4);
    border-radius: 12px;
    @media (max-width: 740px) {
        max-width: 180px;
    }
`

export const Error = styled.span`
    color: red;
    width: 100%;
    text-align: center;
    font-size: 14px;
    bottom: -2px;
    transform: translateY(100%);
    position: absolute;
    @media (max-width: 740px) {
        font-size: 13px;
        bottom: -1px;
    }
`

export const Relative = styled.div`
    width: 100%;
    position: relative;
`

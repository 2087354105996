import React from 'react'
import * as S from './style'

const Footer = ({ pagePaddinInline, sectionRef }) => {
    return (
        <S.Wrapper pagePaddinInline={pagePaddinInline} ref={sectionRef}>
            © Copyright maxshop systems LTD. All rights reserved.
        </S.Wrapper>
    )
}

export default Footer

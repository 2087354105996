import React from 'react';
import { useSection } from '../../hooks';
import { useLabelsContext } from '../../context/LabelsContext';
import { useSectionContentContext } from '../../context/SectionContentContext';
import useContentDialog from '../../hooks/useContentDialog';
import WomanImg from '../../images/section-9/ipadwaiters.png';
import CoffeeImg from '../../images/section-9/w21.png';
import DesktopJoinNow from '../common/DesktopJoinNow';
import JoinNow from '../common/JoinNow';
import ReadMoreLink from '../common/ReadMoreLink/ReadMoreLink';
import { useInView } from 'framer-motion';
import * as S from './style';

const WaitersSection = ({ sectionRef, onVisible, scrollToSection, isRtl }) => {
    const { setRefs } = useSection({ sectionRef, onVisible });
    const isInViewOnce = useInView(sectionRef, { once: true });
    const { waitersSection: labels } = useLabelsContext();
    const { iPadWaiters } = useSectionContentContext();
    const { ContentDialog, onOpenDialog } = useContentDialog({
        content: iPadWaiters?.content,
        scrollToSection,
    });

    return (
        <S.Wrapper ref={setRefs}>
            <S.Inner>
                <S.Content>
                    <S.Text
                        style={{
                            transform: isInViewOnce
                                ? 'translateX(0)'
                                : 'translateX(-500px)',
                            opacity: isInViewOnce ? 1 : 0,
                            transition:
                                'all 0.5s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s',
                        }}
                        className={'textDirection'}
                    >
                        <h2>{labels.title}</h2>
                        {labels.content}
                        <ReadMoreLink onClick={onOpenDialog} />
                        <JoinNow scrollToSection={scrollToSection} />
                    </S.Text>
                    <S.CoffeeImg
                        src={CoffeeImg}
                        isRtl={isRtl}
                        style={{
                            transform: isInViewOnce
                                ? 'translateY(0)'
                                : 'translateY(-500px)',
                            opacity: isInViewOnce ? 1 : 0,
                            transition:
                                'all 0.5s cubic-bezier(0.17, 0.55, 0.55, 1) 0.8s',
                        }}
                    />
                </S.Content>
                <S.ImgContainer>
                    <S.WomanImg
                        src={WomanImg}
                        alt="Waiters"
                        style={{
                            transform: isInViewOnce
                                ? 'translateX(0)'
                                : 'translateX(500px)',
                            opacity: isInViewOnce ? 1 : 0,
                            transition:
                                'all 0.5s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s',
                        }}
                    />
                    <S.PrinterImgMobile src={CoffeeImg} isRtl={isRtl} />
                </S.ImgContainer>
            </S.Inner>
            <DesktopJoinNow scrollToSection={scrollToSection} />
            <ContentDialog />
        </S.Wrapper>
    );
};

export default WaitersSection;

import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Wrapper = styled.div`
    box-sizing: border-box;
    width: 100%;
    min-height: 655px;
    color: black;
    /* padding-block: 5vw 1vw; */
    background-image: url(${({ bgImg }) => bgImg});
    position: relative;
    display: flex;
    box-shadow: 2.939px 4.045px 39.69px 9.31px rgba(0, 0, 0, 0.11);
    margin-block-start: 7%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    @media (max-width: 1100px) {
        min-height: unset;
    }

    @media (max-width: 900px) {
        height: auto;
        padding-block: 35px;
    }
    @media (max-width: 600px) {
    }
`;

export const Inner = styled.div`
    width: 93%;
    margin: 0px auto;
    max-width: 1600px;
    min-width: 1200px;
    direction: ltr;
    position: relative;
    justify-content: space-between;
    display: flex;

    @media (max-width: 1200px) {
        min-width: 100%;
        max-width: 100%;
        padding-inline: 15px;
        box-sizing: border-box;
    }
    @media (max-width: 900px) {
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;
        gap: 20px;
        /* flex-direction: column; */
    }
`;

export const Content = styled.div`
    position: relative;
    width: 58%;
    height: fit-content;
    min-height: 100%;
    display: flex;
    align-items: center;

    @media (max-width: 1100px) {
        width: 450px;
        max-width: 95%;
        margin: 0px auto;
    }
    @media (max-width: 900px) {
        min-width: 80%;
        min-height: fit-content;
        justify-content: center;

        & h2 {
            text-align: center;
        }
    }
`;
export const Text = styled(motion.div)`
    font-size: 34px;
    color: black;
    max-width: 550px;
    display: flex;
    gap: 10px;
    flex-direction: column;
    white-space: pre-wrap;
    & h2 {
        margin: 0;
        font-size: 48px;
        color: black;
    }
    @media (max-width: 1200px) {
        font-size: 28px;

        & h2 {
            font-size: 40px;
        }
    }
`;
export const ImgContainer = styled.div`
    width: 32%;
    margin-right: 5%;
    position: relative;
    display: flex;
    justify-content: center;

    @media (max-width: 900px) {
        min-width: 295px;
    }
`;

export const Img = styled(motion.img)`
    position: relative;
    width: 100%;
    top: 0;
    margin-inline-start: auto;
    display: block;
    margin-inline-end: 8%;
    /* min-width: 465px; */
    margin-block-start: -12%;

    @media (max-width: 1100px) {
        min-width: 320px;
    }
    @media (max-width: 900px) {
        min-width: 90%;
        margin-block-start: 0;
    }
`;

export const Title = styled(motion.h2)`
    margin: 0;
    font-size: 48px;
    color: black;
    margin-block-end: 20px;

    span {
        color: #2ac500;
    }
    @media (max-width: 1200px) {
        font-size: 40px;
    }
`;

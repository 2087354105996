import React, { createContext, useContext, useMemo } from 'react'

const LabelsContext = createContext()

const LabelsContextProvider = ({ children, labels }) => {
    return (
        <LabelsContext.Provider value={labels}>
            {children}
        </LabelsContext.Provider>
    )
}

const useLabelsContext = () => {
    const context = useContext(LabelsContext)
    if (!context) {
        throw new Error('Labels Context Error')
    }
    return context
}

export { LabelsContextProvider, useLabelsContext }

import React, { useEffect, useCallback } from 'react';
import SectionWrapper from '../common/SectionWrapper';
import { useApp, useSection } from '../../hooks';
import UpgradeSales from '../../images/upgrade-sales.png';
import Icon1 from '../../images/reasosn-icons/07_Branch_Network.gif';
import Icon2 from '../../images/reasosn-icons/14_Fast_Transfers.gif';
import Icon3 from '../../images/reasosn-icons/27_Send_Money_Overseas.gif';
import Icon4 from '../../images/reasosn-icons/01_Account_Activity_1.gif';
import { useLabelsContext } from '../../context/LabelsContext';
import AOS from 'aos';
import * as S from './style';

const ContentSection = ({ sectionRef, scrollToSection, onVisible }) => {
    const { contentSection: labels } = useLabelsContext();
    const { setRefs } = useSection({ sectionRef, onVisible });

    const handleContactClick = () => {
        scrollToSection('contactUs');
    };
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);
    return (
        <S.Wrapper ref={setRefs}>
            <S.Inner>
                <S.Content>
                    <S.UpgradeSalesImg
                        src={UpgradeSales}
                        data-aos-once="true"
                        data-aos="fade-right"
                        data-aos-duration="1000"
                        data-aos-delay="250"
                    />
                    <S.Text
                        data-aos-once="true"
                        data-aos="fade-left"
                        data-aos-duration="1000"
                        data-aos-delay="500"
                        className="textDirection"
                    >
                        <h1>{labels.title}</h1>
                        <p>{labels.text}</p>
                    </S.Text>
                </S.Content>
                <S.List>
                    <S.Item
                        data-aos="zoom-in-up"
                        data-aos-once="true"
                        data-aos-delay="1000"
                        data-aos-duration="1000"
                        className={'textDirection'}
                    >
                        <S.IconContainer>
                            <S.Icon src={Icon1} />
                        </S.IconContainer>
                        <span>{labels.reasons.reason1.title}</span>
                        <p>{labels.reasons.reason1.content}</p>
                    </S.Item>
                    <S.Item
                        data-aos="zoom-in-up"
                        data-aos-once="true"
                        data-aos-delay="1000"
                        data-aos-duration="1000"
                        className={'textDirection'}
                    >
                        <S.IconContainer>
                            <S.Icon src={Icon2} />
                        </S.IconContainer>
                        <span>{labels.reasons.reason2.title}</span>
                        <p>{labels.reasons.reason2.content}</p>
                    </S.Item>
                    <S.Item
                        data-aos="zoom-in-up"
                        data-aos-once="true"
                        data-aos-delay="1000"
                        data-aos-duration="1000"
                        className={'textDirection'}
                    >
                        <S.IconContainer>
                            <S.Icon src={Icon3} />
                        </S.IconContainer>
                        <span>{labels.reasons.reason3.title}</span>
                        <p>{labels.reasons.reason3.content}</p>
                    </S.Item>
                    <S.Item
                        data-aos="zoom-in-up"
                        data-aos-once="true"
                        data-aos-delay="1000"
                        data-aos-duration="1000"
                        className={'textDirection'}
                    >
                        <S.IconContainer>
                            <S.Icon src={Icon4} />
                        </S.IconContainer>
                        <span>{labels.reasons.reason4.title}</span>
                        <p>{labels.reasons.reason4.content}</p>
                    </S.Item>
                </S.List>
            </S.Inner>
            <S.JoinUs className={'textDirection'}>
                <span>{labels.joinUs}</span>
                <S.Button onClick={() => handleContactClick()}>
                    {labels.contactUs}
                </S.Button>
            </S.JoinUs>
        </S.Wrapper>
    );
};

export default ContentSection;

import React from 'react'
import {
    Route,
    BrowserRouter,
    HashRouter as Router,
    Routes,
} from 'react-router-dom'
import Main from './Main'

function App() {
    const isMaxShopIO = window.location.hostname.replace('www.', '') === 'maxshop.io'
    const maxshopBasename = window?.maxshop?.basename || '/'
    const languageRouters = [
        { route: '/heb', language: 'heb' },
        { route: '/eng', language: 'eng' },
    ]
    const defaultLanguage = isMaxShopIO ? 'eng' : 'heb'

    return (
        <BrowserRouter basename={maxshopBasename}>
            <Routes>
                {languageRouters.map(({ route, language }) => {
                    return (
                        <Route
                            path={`${route}/*`}
                            element={
                                <Main
                                    language={language}
                                    isMaxShopIO={isMaxShopIO}
                                />
                            }
                        />
                    )
                })}

                <Route
                    exact
                    path="/*"
                    element={
                        <Main
                            language={defaultLanguage}
                            isMaxShopIO={isMaxShopIO}
                        />
                    }
                />
            </Routes>
        </BrowserRouter>
    )
}

export default App

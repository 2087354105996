import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Link as RLink } from 'react-router-dom';

export const WrapperHolder = styled.div`
    width: 100%;
    height: fit-content;
    position: ${({ isMenuSticky }) => (isMenuSticky ? 'fixed' : 'absolute')};
    background: ${({ isMenuSticky }) => (!isMenuSticky ? '#41d323' : 'none')};
    z-index: 999999999;
    top: 0;
    display: flex;
`;
export const Wrapper = styled(motion.div)`
    box-sizing: border-box;
    width: 100%;
    background: black;
    box-shadow: 0px 0px 46.17px 10.83px rgba(0, 0, 0, 0.22);
    padding-block: ${({ isMenuSticky, isMobileMenu }) =>
        isMenuSticky ? '10px' : isMobileMenu ? '10px' : '20px'};
    color: white;
    display: flex;
    align-items: center;
    position: relative;
    top: 0;
    right: 0;
    z-index: 99999;
    ${({ isMobileMenu }) =>
        isMobileMenu &&
        `{
       flex-wrap:wrap;
       padding-bottom:0;
    `}
`;
export const LogoImg = styled.img`
    width: ${({ isMenuSticky }) => (isMenuSticky ? '55px' : '75px')};
`;

export const Telephone = styled.a`
    font-size: 22px;
    color: white;
    margin-inline: auto;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 8px;
    @media (max-width: 1160px) {
        display: none;
    }
`;
export const PhoneIcon = styled.img`
    width: 25px;
    margin-top: -7px;
`;

export const LinksContainer = styled.div`
    display: flex;
    gap: 15px;
    @media (max-width: 1160px) {
        margin-inline-start: auto;
    }
`;
export const Link = styled(RLink)`
    box-sizing: border-box;
    text-decoration: none;
    display: flex;
    color: black;
    font-size: 22px;
    min-height: 45px;
    font-weight: 700;
    padding: 10px;
    border-radius: 20px;
    background: white;
    align-items: center;
    position: relative;
    z-index: auto;
`;
export const Button = styled.a`
    box-sizing: border-box;
    text-decoration: none;
    display: flex;
    color: black;
    font-size: 22px;
    min-height: 45px;
    font-weight: 700;
    padding: 10px;
    border-radius: 20px;
    background: white;
    align-items: center;
    position: relative;
    z-index: auto;

    & .Dropdown-placeholder {
        background: none;
        border: 0;
    }
    & .Dropdown-control {
        border: 0;
        padding: 0;
    }
    & .Dropdown-menu {
        margin-block-start: 13px;
        width: 80px;
    }
    & .Dropdown-arrow {
        display: none;
    }
`;
export const Inner = styled(motion.div)`
    width: 93%;
    margin: 0px auto;
    max-width: 1600px;
    min-width: 1200px;
    position: relative;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 99999;
    box-sizing: border-box;
    ${({ isMobileMenu }) =>
        isMobileMenu &&
        `{
       flex-wrap:wrap;
       justify-content:space-between;
    `}

    @media (max-width: 1200px) {
        max-width: 100%;
        min-width: 100%;
        padding-inline: 15px;
    }
`;

export const InnerMobile = styled(motion.div)`
    flex: 1;
    padding-inline: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5%;
    & ${Button},& ${Link} {
        font-size: 20px;
        height: 25px;
        max-height: 35px;
        min-height: 35px;
        min-width: 80px;
        justify-content: center;
        padding-block: 0;

        @media (max-width: 1100px) {
            font-size: 18px;
            max-height: 25px;
            min-height: 25px;
        }
    }
    & ${LogoImg} {
        width: ${({ isMenuSticky }) => (isMenuSticky ? '65px' : '80px')};

        @media (max-width: 1100px) {
            width: ${({ isMenuSticky }) => (isMenuSticky ? '50px' : '60px')};
        }
    }
    padding-bottom: ${({ isMenuSticky }) => (isMenuSticky ? '5px' : '10px')};
`;

export const IconLink = styled.a`
    display: flex;
    width: ${({ width }) => (width ? `${width}px` : '45px')};
    height: fit-content;
    margin: 0;
    padding: 0;
    .WhatsappIcon {
        width: 35px;
    }
    @media (max-width: 1100px) {
        width: ${({ width }) => (width ? `${width}px` : '20')};
        .WhatsappIcon {
            width: 25px;
        }
    }
    & img {
        width: 100%;
        display: block;
    }
    & img path {
        fill: white;
    }
`;
export const MobileContainer = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;

    & ${Link} {
        border-radius: 10px;
        min-height: 30px;
        font-size: 18px;
        padding: 7px 10px;

        width: ${({ isMenuSticky }) => (isMenuSticky ? '50px' : '60px')};
    }
    & ${Button} {
        border-radius: 10px;
        min-height: 30px;
        font-size: 18px;
        padding: 7px 10px;
    }
`;

export const ButtonsContainer = styled.div`
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const CallButton = styled.div`
    display: flex;
    gap: 5px;
    font-size: 26px;
    align-items: center;

    @media (max-width: 1100px) {
        font-size: 20px;
    }
    span {
        padding-top: 3px;
        display: flex;
        align-items: center;
    }

    @media (max-width: 650px) {
        span {
            display: none;
        }
    }
`;

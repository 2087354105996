import styled from 'styled-components';
import { default as JoinNowButton } from '../JoinNow/JoinNow';

export const Container = styled.div`
    position: relative;
    width: 750px;
    max-width: 90%;
    height: 500px;
    max-height: 500px;
    max-height: 80vh;
    border-radius: 25px;
    background-color: white;
    border: 2px solid #aaaaaa;
    display: flex;
    flex-direction: column;
    padding: 20px;
    padding-bottom: 35px;
    box-sizing: border-box;
    gap: 20px;
    margin: 0px auto;
    z-index: 999999;
    /* @media (max-width: 900px) { */
    margin-top: 40px;
    /* } */
`;

export const Inner = styled.div`
    display: flex;
    padding-inline-end: 10px;
    flex-direction: column;
    box-sizing: border-box;
    flex: 1;
    width: 100%;
    overflow-y: auto;
    font-size: 24px;
    line-height: 1.3;
    scrollbar-color: #6969dd #e0e0e0;
    & p {
        margin: 10px 0;
    }
    ::-webkit-scrollbar {
        width: 5px;
    }

    ::-webkit-scrollbar-track {
        border: 0;
        outline: 0;

        background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
        background-color: rgb(205, 205, 205);
        border-radius: 20px;

        /* outline: 1px solid slategrey; */
    }
`;

export const CloseButton = styled.button`
    outline: 0;
    border: 0;
    border-radius: 50%;
    background: #f9146a;
    height: 34px;
    width: 34px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -30px;
    transform: translateY(-50%);
    left: 0;
    cursor: pointer;
    transition: 0.3s all ease-in-out;
    & svg {
        height: 25px;
    }
    &:hover {
        color: #f9146a;
        background: white;
    }

    @media (max-width: 900px) {
        top: -15px;
        height: 20px;
        width: 20px;
        & svg {
            height: 16px;
        }
    }
`;

export const JoinNowContainer = styled.div`
    position: absolute;
    bottom: 0;
    transform: translate(-50%, 50%);
    left: 50%;
`;

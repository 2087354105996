import React, { memo } from 'react';
import { Dialog as MaterialUIDialog } from '@mui/material';
import JoinNow from '../JoinNow/JoinNow';
import CloseIcon from '@mui/icons-material/Close';
import * as S from './style';
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const Dialog = ({ content, isOpen, onClose, onScrollToSection }) => {
    const options = {
        renderNode: {
            [BLOCKS.EMBEDDED_ENTRY]: (node) => <div node={node} />,
        },
    };
    const renderedContent = documentToReactComponents(content, options);
    return isOpen ? (
        <MaterialUIDialog
            PaperProps={{
                style: {
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                    maxWidth: 'unset',
                    maxHeight: 'unset',
                    overflow: 'unset',
                    width: '100%',
                    zIndex: 999999,
                },
            }}
            open={true}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <S.Container className="textDirection">
                <S.CloseButton onClick={onClose}>
                    <CloseIcon width={20} fontSize="small" />
                </S.CloseButton>
                <S.Inner className={'textDirection'}>{renderedContent}</S.Inner>
                <S.JoinNowContainer>
                    <JoinNow
                        scrollToSection={onScrollToSection}
                        isAlwayVisible={true}
                        marginBlock={0}
                    />
                </S.JoinNowContainer>
            </S.Container>
        </MaterialUIDialog>
    ) : (
        <></>
    );
};

export default memo(Dialog);

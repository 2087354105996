import React, { useState, useEffect, useRef, memo } from 'react';
import { useLabelsContext } from '../../context/LabelsContext';
import Menu from './Menu';
import HamburgerButton from './HamburgerButton';
import * as S from './style';
import Logo from '../../images/maxshoplogo.png';
import WhatsappIcon from '../../images/whatsapp.svg';
import PhoneIcon from '../../images/phone.png';
import TelephoneIcon from '../../images/icon-tel.png';
import UserLoginIcon from '../../images/user-login.svg';
import { useApp } from '../../hooks';
import MobileMenu from './MobileMenu';
import { motion, AnimatePresence } from 'framer-motion';
import { useInView } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

const Header = ({
    refs,
    isVisible = true,
    isMenuSticky = false,
    language = 'eng',
    isMaxShopIO,
    visibleSection = '',
}) => {
    const navigate = useNavigate();

    const [isMobileMenu, setIsMobileMenu] = useState(true);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const { screenSize } = useApp();
    const { header: labels } = useLabelsContext();
    const headerRed = useRef();
    const isInView = useInView(headerRed, { once: !isMenuSticky });
    const whatsAppLink =
        language === 'heb'
            ? 'https://api.whatsapp.com/send?phone=972527477479&text=I%20am%20interested%20in%20ordering%20a%20Maxshop%20service%0A%0A%D7%90%D7%91%D7%A7%D7%A9%20%D7%A9%D7%99%D7%97%D7%96%D7%A8%D7%95%20%D7%90%D7%9C%D7%99%20%D7%9C%D7%92%D7%91%D7%99%20%D7%94%D7%96%D7%9E%D7%A0%D7%AA%20%D7%A9%D7%99%D7%A8%D7%95%D7%AA%20%D7%91%D7%9E%D7%A7%D7%A1%D7%A9%D7%95%D7%A4'
            : 'https://bit.ly/3XX6RM0';

    const loginLink = `https://maxshop.co.il/${language}`;

    // useEffect(() => {
    //     if (screenSize.innerWidth < 1410) {
    //         setIsMobileMenu(true);
    //     } else {
    //         setIsMobileMenu(false);
    //     }
    // }, [screenSize.innerWidth]);

    const handleMobileMenuButtonClick = () => {
        setIsMobileMenuOpen((state) => !state);
    };

    const handleLanguageChange = (lang) => {
        navigate(`/${lang.value}`);
    };

    const scrollTop = () => {
        const scrollOptions = {
            top: 0,
            left: 0,
            behavior: 'smooth',
        };
        window.scroll(scrollOptions);
    };

    const languageRouters = [
        { value: 'heb', label: 'עברית' },
        { value: 'eng', label: 'English' },
    ];

    const languageButton = (
        <S.Button isMobileMenu={isMobileMenu}>
            <Dropdown
                options={languageRouters}
                onChange={(value) => {
                    handleLanguageChange(value);
                }}
                placeholder={'EN | עב'}
            />
        </S.Button>
    );

    const loginButton = isMaxShopIO ? (
        <S.Button isMobileMenu={isMobileMenu} href={`${loginLink}/login`}>
            {labels.login}
        </S.Button>
    ) : (
        <S.Link isMobileMenu={isMobileMenu} to={`/${language}/login`}>
            {labels.login}
        </S.Link>
    );

    const HeaderLinks = () => (
        <>
            {loginButton}
            {languageButton}
        </>
    );
    const handleCallClick = () => {
        const telLink = `tel:${TELEPHONE_NUMBER}`;
        window.location.href = telLink;
    };
    return (
        <AnimatePresence>
            <S.WrapperHolder ref={headerRed} isMenuSticky={isMenuSticky}>
                <S.Wrapper
                    isMobileMenu={isMobileMenu}
                    isMenuSticky={isMenuSticky}
                    style={
                        isMenuSticky && {
                            transform:
                                isVisible && isInView
                                    ? 'translateY(0)'
                                    : 'translateY(-400px)',
                            opacity: 1,
                            transition:
                                'all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.1s',
                        }
                    }
                >
                    {!isMobileMenu ? (
                        <S.Inner isMobileMenu={isMobileMenu}>
                            <motion.div
                                whileHover={{ scale: 1.2 }}
                                whileTap={{ scale: 0.8 }}
                            >
                                <S.LogoImg
                                    src={Logo}
                                    onClick={() => scrollTop()}
                                    isMenuSticky={isMenuSticky}
                                />
                            </motion.div>
                            {!isMobileMenu && (
                                <Menu
                                    refs={refs}
                                    visibleSection={visibleSection}
                                />
                            )}
                            <S.Telephone href={`tel:${TELEPHONE_NUMBER}`}>
                                {TELEPHONE_NUMBER}
                                <S.PhoneIcon src={TelephoneIcon} />
                            </S.Telephone>
                            {!isMobileMenu && (
                                <S.LinksContainer>
                                    <HeaderLinks />
                                </S.LinksContainer>
                            )}
                            {isMobileMenu && (
                                <>
                                    <S.MobileContainer>
                                        <S.IconLink href={whatsAppLink}>
                                            <img src={WhatsappIcon} />
                                        </S.IconLink>
                                        <HeaderLinks />
                                    </S.MobileContainer>
                                </>
                            )}
                        </S.Inner>
                    ) : (
                        <S.InnerMobile isMenuSticky={isMenuSticky}>
                            <S.ButtonsContainer>
                                {languageButton}
                                <S.IconLink
                                    href={whatsAppLink}
                                    width={25}
                                    className="WhatsappIcon"
                                >
                                    <img src={WhatsappIcon} />
                                </S.IconLink>
                            </S.ButtonsContainer>
                            <motion.div
                                whileHover={{ scale: 1.2 }}
                                whileTap={{ scale: 0.8 }}
                            >
                                <S.LogoImg
                                    src={Logo}
                                    onClick={() => scrollTop()}
                                    isMenuSticky={isMenuSticky}
                                />
                            </motion.div>
                            <S.ButtonsContainer>
                                <S.CallButton onClick={handleCallClick}>
                                    <S.IconLink width={20}>
                                        <img src={PhoneIcon} />
                                    </S.IconLink>
                                    <span>{TELEPHONE_NUMBER}</span>
                                </S.CallButton>
                                {loginButton}
                            </S.ButtonsContainer>
                        </S.InnerMobile>
                    )}
                    {isMobileMenu && (
                        <MobileMenu
                            refs={refs}
                            isMobileMenu={isMobileMenu}
                            isOpen={isMobileMenuOpen}
                            onMenuItemClick={() => {
                                setIsMobileMenuOpen((state) => !state);
                            }}
                            visibleSection={visibleSection}
                        />
                    )}
                </S.Wrapper>
            </S.WrapperHolder>
        </AnimatePresence>
    );
};

export default memo(Header);
function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
}

const TELEPHONE_NUMBER = '077-4-077-077';

import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { LabelsContextProvider } from './context/LabelsContext';
import { SectionContentContextProvider } from './context/SectionContentContext';
import Home from './pages/Home';
import Login from './pages/Login';
import PageNotFound from './pages/PageNotFound';
import './fonts/FbAbsoluti/FbAbsoluti.css';
import 'aos/dist/aos.css';
import { eng, heb } from './languages';
import { Route, Routes } from 'react-router-dom';
import ReactGA from 'react-ga4';

function Main({ language, isMaxShopIO = false }) {
    const isHebrewLanguage = language === 'heb';
    const labels = isHebrewLanguage ? heb() : eng();
    const contentful = require('contentful');

    const client = contentful.createClient({
        space: '545n6zuthwrc',
        environment: 'master', // defaults to 'master' if not set
        accessToken: 'vTU-Dt7wIgBYbWvFUqjf9x4tbS4E7aFVAyWE72WLYo4',
    });

    ReactGA.initialize("G-KS51JMJRD7");
    const [sectionsContent, setSectionsContent] = useState({});

    useEffect(() => {
        const fetchContent = async () => {
            const posts = await getContent();
            const mappedContent = posts?.items.reduce((acc, { fields }) => {
                const sectionId = fields?.sectionId;
                if (sectionId) {
                    acc[sectionId] = {
                        title: fields.title1,
                        content: isHebrewLanguage
                            ? fields.contentHebrew
                            : fields.contentEng,
                    };
                }
                return acc;
            }, {});

            setSectionsContent(mappedContent);
        };
        fetchContent();
    }, []);

    const getContent = async () => {
        try {
            const response = await client.getEntries();
            return response;
        } catch (error) {
            console.error('Error fetching blog posts:', error);
            return [];
        }
    };
    return (
        <LabelsContextProvider labels={labels}>
            <SectionContentContextProvider mappedContent={sectionsContent}>
                <Helmet>
                    <title>{labels.title}</title>
                    <meta name="description" content={labels.desciption} />
                    <meta name="keywords" content={labels.keywords} />
                </Helmet>

                <Routes>
                    {!isMaxShopIO && (
                        <Route
                            exact
                            path={'/login'}
                            element={<Login language={language} />}
                        />
                    )}
                    <Route
                        path={'/'}
                        element={
                            <Home
                                language={language}
                                isMaxShopIO={isMaxShopIO}
                            />
                        }
                    />
                    <Route
                        path={'/qr'}
                        element={
                            <Home
                                language={language}
                                isMaxShopIO={isMaxShopIO}
                            />
                        }
                    />
                    <Route path="*" element={<PageNotFound />} />
                </Routes>
            </SectionContentContextProvider>
        </LabelsContextProvider>
    );
}

export default Main;

import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    .textDirection {
        ${({ isRTL }) => isRTL && ` direction:rtl;`}
    }  
    }
`;

export default GlobalStyle;

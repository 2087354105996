const eng = () => {
    return {
        title: 'maxshop - Upgrade your sales',
        description: `Create the best purchasing experience for businesses and end customers On a personal sales site, at a self-ordering kiosk and an Most advanced cloud checkout The knowledge we have gained in the world of e-commerce sets us apart from everything you know Upgrade your sales with MaxShop today`,
        keywords:
            'Ordering station, kiosk station, restaurant kiosk, cloud cash register, waiter tablet, cloud cash register, ordering site, fast food ordering site, tablet with menu, virtual store, ordering application, self-service station, fast food service station, restaurant cash register, cash register with Table map, SMS invoices, kds screen, pos, KDS screen, Digital green invoices, iPad waiters, qr barcode for orders',
        joinNow: 'Join Now',
        menuItems: {
            tech: 'about',
            maxKiosk: 'kiosk',
            web: 'e-commerce',
            eApp: 'app',
            maxMenuTab: 'digital menu',
            greenReceipts: 'green invoice',
            contactUs: 'contact us',
        },
        moreMenuItems: {
            tech: 'about',
            maxKiosk: 'kiosk',
            web: 'e-commerce',
            eApp: 'app',
            maxMenuTab: 'digital menu',
            iPadWaiters: 'waiters tablet',
            tabPos: 'cloud pos',
            kds: 'kds',
            greenReceipts: 'green invoice',
            qr: 'qr',
            contactUs: 'contact us',
        },
        readMore: 'Read more',

        contactSection: {
            services: [
                'maxkiosk',
                'webDelivery',
                'appDelivery',
                'menuOrder',
                'maxshopPos',
                'kds',
                'qr',
                'green receipts',
                'iPad waiters',
            ],
            title: 'Contact Us',
            content: 'Advance to Maxshop today',
            name: 'Name',
            phone: 'Phone',
            select: 'Select',
            company: 'Company',
            comments: 'Comments',
            thankYou: 'Thank you',
            contactShortly: 'OUR TEAM WILL CONTACT YOU SHORTLY',
            sending: 'Sending',
        },
        WelcomeSection: {
            content:
                'We invite you \nto get to know \nthe Most advanced\nself-service platform',
            contactButton: 'Contact Us',
        },
        contentSection: {
            title: 'Upgrade your sales with MaxShop',
            text: 'The magic of practical experience and thinking about every system detail will work for you in turning maximum surfers into buyers',
            reasons: {
                reason1: {
                    title: 'Full compatibility',
                    content:
                        'all types of devices and use of the best cloud servers',
                },
                reason2: {
                    title: 'Simple management',
                    content: 'allows you to continue manage your business',
                },
                reason3: {
                    title: 'Countless modules',
                    content: 'that make us the only platform you will need',
                },
                reason4: {
                    title: 'Friendliness',
                    content: 'Your customers will fall in love and order Again',
                },
            },
            joinUs: 'Want to join?',
            contactUs: 'Contact us',
        },
        kioskSection: {
            title: 'maxshop kiosk',
            content:
                'Working for you non-stop Like the biggest chains\n Exclusive solution, receiving payment in - credit + cash',
        },
        ecommerceSection: {
            title: 'maxshop e-commerce',
            content: `personal website with a Perfect \n purchase experience\n look and speak your business\n For fast food and a supermarket`,
        },
        appSection: {
            title: 'delivery app',
            content: "Always to be in the customer's pocket",
        },
        menuTabSection: {
            title: 'maxshop digital menu',
            content:
                'allows the customer\n to see a menu and\n Choose a table and order\n With Direct connection\n to the kitchen',
        },
        tabPosSection: {
            title: 'maxshop cloud pos',
            content:
                'Cloud POS for your business simple management from anywhere with an app, And in addition - a perfect connection to any of maxshop other products',
        },
        kdsSection: {
            title: 'maxshop KDS screen',
            content:
                'Data for the kitchen in real time in a centralized place\nto respond quickly to changes\nin demand even at the height of pressure\nsave you many costs of mistakes \n And above all to accumulate information\nthat is worth a lot of money to your business \n',
        },
        waitersSection: {
            title: 'maxshop waiters tablet',
            content:
                'Your Waiters can take orders quickly and accurately\nwhich can improve overall service efficiency.\nWith an maxshop iPad, orders can be taken at the table\nand sent directly to the kitchen,\nreducing the need for handwritten orders and reducing the likelihood of mistakes',
        },
        receiptsSection: {
            title: 'maxshop Digital',
            secondTitle: 'green invoices',
            content:
                'Information about your customers\nis a huge power for your business!\nBy collecting information from customers\nwho have made a purchase Your business\nyou can market the products for repeat purchase',
        },
        paymentSection: {
            title: 'maxshop QR payment',
            content:
                'Ordering food in restaurants\nand fast food businesses\nby Using maxshop QR code\nfor quick ordering and payment\nYour customers can scan the code\nwith the smartphone camera and access\na menu containing images\nand information that prevents mistakes',
        },
        header: {
            login: 'Login',
        },
        loginPage: {
            title: 'Admin login',
            content: 'Have an account? Sign in now',
            error: 'No admin was found with these details',
            domain: 'Website address',
            userName: 'user name',
            password: 'password',
            rememberMe: 'Keep me signed in on this device',
            login: 'Login',
            onLogin: 'Use of this Site is subject to express',
            terms: 'terms of use',
            use: 'By using this site, you signify that you agree to be bound by these',
            copyRight: '© Copyright maxshop systems LTD. All rights reserved.',
            isRequired: '* Field Is required',
            loginPage: 'Admin login',
        },
    };
};
export default eng;

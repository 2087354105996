import styled from 'styled-components'

export const Wrapper = styled.div`
    box-sizing: border-box;
    position: relative;
    width: 100%;
    background: ${({ bgColor }) => bgColor ?? 'white'};
    min-height: 800px;
    color: white;
    padding-block: ${({ paddingBlock }) => paddingBlock ?? '5vw'};
    min-height: ${({ minHeight }) => minHeight ?? '500px'};
    max-height: ${({ maxHeight }) => maxHeight ?? 'auto'};
    box-shadow: ${({ boxShadow }) => boxShadow ?? 'none'};
`
export const Inner = styled.div`
    width: 93%;
    margin: 0px auto;
    max-width: 1600px;
    min-width: 1200px;

    position: relative;
    display: flex;
`

import styled from 'styled-components'

export const Input = styled.input`
    width: 100%;
    min-height: 100%;
    background: none;
    border: 0;
    outline: 0;
    display: flex;
    font-size: 22px;
    color: black;
`
export const TextArea = styled.textarea`
    width: 100%;
    min-height: 90px;
    background: none;
    border: 0;
    outline: 0;
    display: flex;
    font-size: 22px;
    padding-block-start: 8px;
    color: black;
    ::-webkit-resizer {
        display: none;
    }
`
export const Container = styled.div`
    width: 100%;
    height: fit-content;
    min-height: 50px;
    display: flex;
    background: ${({ hasError }) => (hasError ? '#e0a7a7' : '#ededed')};
    border: 2px solid white;
    border-color: ${({ hasError }) => (hasError ? '#d68d8d' : 'white')};
    transition: all 0.3s ease-out;
    box-sizing: border-box;
    border-radius: 35px;
    padding-inline: 15px;
    position: relative;
    & .dropdown-container {
        background: none !important;
        border: 0 !important;
        width: 100% !important;
        color: black;
        font-size: 22px;
        outline: 0 !important;
        min-height: 100%;
        display: flex;
        align-items: center;
        box-shadow: 0 0 0 !important;
    }
    & .dropdown-heading {
        min-height: 100%;
    }
    & .multi-select {
        position: absolute;
        width: calc(100% - 15px);
        height: 100%;
        outline: 0 !important;
    }

    & .gray {
        color: #757575;
    }
`

import styled from 'styled-components';

export const Link = styled.a`
    font-size: 34px;
    color: black;
    font-weight: 700;
    cursor: pointer;

    @media (max-width: 900px) {
        font-size: 28px;
    }
`;

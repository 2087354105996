import React from 'react';
import JoinNow from '../common/JoinNow';
import CircleImg from '../../images/section-7/circle.png';
import DeviceImg from '../../images/section-7/maxshoppos.png';
import { useLabelsContext } from '../../context/LabelsContext';
import { useSection } from '../../hooks';
import { useInView } from 'framer-motion';
import * as S from './style';

const TabPosSection = ({ sectionRef, scrollToSection, onVisible }) => {
    const { tabPosSection: labels } = useLabelsContext();
    const isInViewOnce = useInView(sectionRef, { once: true });
    const { setRefs } = useSection({ sectionRef, onVisible });

    return (
        <S.Wrapper ref={setRefs} bgColor={'#1b1b1b'}>
            <S.Inner>
                <S.Content className={'textDirection'}>
                    <S.Text
                        style={{
                            transform: isInViewOnce
                                ? 'translateX(0)'
                                : 'translateX(-500px)',
                            opacity: isInViewOnce ? 1 : 0,
                            transition:
                                'all 0.5s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s',
                        }}
                    >
                        <h2>{labels.title}</h2>
                        {labels.content}
                    </S.Text>
                </S.Content>
                <JoinNow scrollToSection={scrollToSection} />

                <S.ImgContainer>
                    <S.Circle
                        src={CircleImg}
                        animate={{
                            scale: [0.7, 1],
                        }}
                        transition={{
                            ease: 'linear',
                            repeat: Infinity,
                            repeatType: 'mirror',
                            duration: 2,
                        }}
                    />
                    <S.DeviceImg
                        src={DeviceImg}
                        style={{
                            transform: isInViewOnce
                                ? 'translateX(0)'
                                : 'translateX(500px)',
                            opacity: isInViewOnce ? 1 : 0,
                            transition:
                                'all 0.5s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s',
                        }}
                    />
                </S.ImgContainer>
            </S.Inner>
        </S.Wrapper>
    );
};

export default TabPosSection;

import React, { useCallback, useEffect, useRef } from 'react';
import * as S from './style';
import { useLabelsContext } from '../../../context/LabelsContext';
const MobileMenu = ({
    refs,
    isMobileMenu = false,
    isOpen,
    onMenuItemClick,
    visibleSection,
}) => {
    const scrollableContainerRef = useRef(null);
    const { menuItems, moreMenuItems } = useLabelsContext();
    const handleClick = useCallback((key) => {
        if (!refs[key].current) return;
        const scrollOptions = {
            top: refs[key].current.offsetTop - 110,
            left: 0,
            behavior: 'smooth',
        };
        window.scroll(scrollOptions);
        onMenuItemClick();
    });

    const scrollToTarget = () => {
        const container = scrollableContainerRef.current;
        const target = document.getElementById(visibleSection);

        if (container && target) {
            const containerWidth = container.offsetWidth;
            const targetPosition = target.offsetLeft;
            const targetWidth = target.offsetWidth;

            const scrollPosition =
                targetPosition + targetWidth / 2 - containerWidth / 2;

            container.scrollTo({ left: scrollPosition, behavior: 'smooth' });
        }
    };

    useEffect(scrollToTarget, [visibleSection]);
    const allMenuItems = isMobileMenu ? moreMenuItems : menuItems;
    return (
        <S.Wrapper ref={scrollableContainerRef}>
            <S.Inner className="textDirection">
                {Object.keys(allMenuItems).map((key, index) => (
                    <S.Item
                        onClick={() => handleClick(key)}
                        key={index}
                        isMobileMenu={isMobileMenu}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.5 }}
                        exit={{ opacity: 1, scale: 0 }}
                        isActive={visibleSection === key}
                        id={key}
                    >
                        {allMenuItems[key]}
                    </S.Item>
                ))}
            </S.Inner>
        </S.Wrapper>
    );
};

export default MobileMenu;

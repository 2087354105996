import React, { memo, useCallback, useEffect } from 'react';
import { useLabelsContext } from '../../../context/LabelsContext';

import * as S from './style';

const Menu = ({ refs, isMobileMenu = false, isOpen, visibleSection }) => {
    const { menuItems } = useLabelsContext();
    const handleClick = useCallback((key) => {
        if (!refs[key].current) return;
        const scrollOptions = {
            top: refs[key].current.offsetTop - 80,
            left: 0,
            behavior: 'smooth',
        };
        window.scroll(scrollOptions);
    });
    const isMenuVisible = true;

    return (
        <S.Wrapper isMobileMenu={isMobileMenu} className="textDirection">
            {isMenuVisible && (
                <>
                    {Object.keys(menuItems).map((key, index) => {
                        return (
                            <S.Item
                                onClick={() => handleClick(key)}
                                key={index}
                                isMobileMenu={isMobileMenu}
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 0.5 }}
                                exit={{ opacity: 1, scale: 0 }}
                                isActive={key === visibleSection}
                            >
                                {menuItems[key]}
                            </S.Item>
                        );
                    })}
                </>
            )}
        </S.Wrapper>
    );
};

export default memo(Menu);

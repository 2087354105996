import React from 'react'
import JoinNow from '../common/JoinNow'
import WomeImg from '../../images/screen-5/women.png'
import IphoneImg from '../../images/screen-5/iphone.png'
import CheeseImg from '../../images/screen-5/cheese.png'
import CornImg from '../../images/screen-5/corn.png'
import OnionImg from '../../images/screen-5/onion.png'
import { useLabelsContext } from '../../context/LabelsContext'
import { useApp, useSection } from '../../hooks'
import * as S from './style'
import { useInView } from 'framer-motion'

const AppSection = ({ sectionRef, scrollToSection, onVisible }) => {
    const { appSection: labels } = useLabelsContext()
    const { screenSize } = useApp()
    const { setRefs } = useSection({ sectionRef, onVisible })
    const isInViewOnce = useInView(sectionRef, { once: true })

    return (
        <S.Wrapper ref={setRefs}>
            <S.Inner>
                <S.ContentContainer>
                    {screenSize.innerWidth > 1200 && (
                        <S.WomeImg src={WomeImg} className={'flipImg'} />
                    )}
                    <S.Content>
                        <h2>{labels.title}</h2>
                        {labels.content}
                    </S.Content>
                </S.ContentContainer>
                <JoinNow scrollToSection={scrollToSection} />
                <S.ImgsContainer>
                    <S.IphoneImg
                        src={IphoneImg}
                        style={{
                            transform: isInViewOnce
                                ? 'translateY(0)'
                                : 'translateY(-500px)',
                            opacity: isInViewOnce ? 1 : 0,
                            transition:
                                'all 0.5s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s',
                            delay: 500,
                        }}
                    />
                    <S.CheeseImg
                        src={CheeseImg}
                        style={{
                            transform: isInViewOnce
                                ? 'translateX(0)'
                                : 'translateX(500px)',
                            opacity: isInViewOnce ? 1 : 0,
                            transition:
                                'all 0.5s cubic-bezier(0.17, 0.55, 0.55, 1) 0.7s',
                        }}
                    />
                    <S.CornImg
                        src={CornImg}
                        style={{
                            transform: isInViewOnce
                                ? 'translateX(0)'
                                : 'translateX(500px)',
                            opacity: isInViewOnce ? 1 : 0,
                            transition:
                                'all 0.5s cubic-bezier(0.17, 0.55, 0.55, 1) 1s',
                            delay: 500,
                        }}
                    />
                    <S.OnionImg
                        src={OnionImg}
                        style={{
                            transform: isInViewOnce
                                ? 'translateX(0)'
                                : 'translateX(-500px)',
                            opacity: isInViewOnce ? 1 : 0,
                            transition:
                                'all 0.5s cubic-bezier(0.17, 0.55, 0.55, 1) 1.3s',
                            delay: 500,
                        }}
                    />
                </S.ImgsContainer>
            </S.Inner>
            {screenSize.innerWidth < 1200 && (
                <S.WomeImg src={WomeImg} className={'flipImg'} />
            )}
        </S.Wrapper>
    )
}

export default AppSection

import styled from 'styled-components'
import { motion } from 'framer-motion'

export const Wrapper = styled.ul`
    margin-inline-start: 45px;
    transition: all 0.5s ease;
    transition-duration: 251ms, 167ms;

    padding: 0;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    ${({ isMobileMenu }) =>
        isMobileMenu &&
        `
        flex-direction:column;
        width:100%;
        margin-inline-start:0;
        margin:0;height:fit-content;
    `}
`
export const Item = styled(motion.li)`
    padding-inline: 8px;
    padding-block: 3px;
    display: flex;
    letter-spacing: 2px;
    min-width: ${({ isMobileMenu }) => (isMobileMenu ? `100%` : 'auto')};
    align-items: center;
    list-style-type: none;
    border-inline-end: 1px solid white;
    font-size: 22px;
    cursor: pointer;
    ${({ isMobileMenu }) =>
        isMobileMenu &&
        `
        border-inline-end: 0 solid white;
        border-block-end: 1px solid white;
        padding-block:5px;
        width:100%;
    `}
    &:hover {
        color: #66ff66;
    }

    &:last-child {
        border: 0;
    }

    &:first-child {
        ${({ isMobileMenu }) =>
            isMobileMenu &&
            `
            border: 0;
            gap: 8px;
            justify-content:center;
        `}
    }
`
export const Link = styled.a`
    color: black;
    background: white;
    padding: 8px;
    border-radius: 10px;
    font-size: 18px;
    font-weight: 700;
`

import React from 'react'
import Laptop from '../../images/screen-4/laptop.png'
import Iphone from '../../images/screen-4/iphone.png'
import Fruit1 from '../../images/screen-4/fruit-1.png'
import Fruit2 from '../../images/screen-4/fruit-2.png'
import Fruit3 from '../../images/screen-4/fruit-3.png'
import * as S from './style'
import { useInView } from 'framer-motion'
import JoinNow from '../common/JoinNow'
import { useApp, useSection } from '../../hooks/'
import { useLabelsContext } from '../../context/LabelsContext'

const EcommerceSection = ({ sectionRef, scrollToSection, onVisible }) => {
    const isInViewOnce = useInView(sectionRef, { once: false })
    const { screenSize } = useApp()
    const { ecommerceSection: labels } = useLabelsContext()
    const { setRefs } = useSection({ sectionRef, onVisible })

    return (
        <S.Wrapper ref={setRefs}>
            <S.Inner>
                <S.Fruit3
                    src={Fruit3}
                    style={{
                        transform: isInViewOnce ? 'none' : 'translateY(-200px)',
                        opacity: isInViewOnce ? 1 : 0,
                        transition:
                            'all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s',
                    }}
                />
                <S.ImgsContainer>
                    <S.Laptop src={Laptop} />
                    <S.Iphone src={Iphone} />
                    <S.Fruit1
                        src={Fruit1}
                        style={{
                            transform: isInViewOnce
                                ? 'translateY(0)'
                                : 'translateY(-200px)',
                            opacity: isInViewOnce ? 1 : 0,
                            transition:
                                'all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s',
                        }}
                        exit={{ opacity: 0 }}
                    />
                    <S.Fruit2
                        src={Fruit2}
                        style={{
                            transform: isInViewOnce
                                ? 'none'
                                : 'translateX(-200px)',
                            opacity: isInViewOnce ? 1 : 0,
                            transition:
                                'all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 1s',
                        }}
                    />
                </S.ImgsContainer>
                <JoinNow scrollToSection={scrollToSection} />
                <S.Content className="textDirection">
                    <h2>{labels.title}</h2>
                    <p>{labels.content}</p>
                </S.Content>
            </S.Inner>
        </S.Wrapper>
    )
}

export default EcommerceSection

import React, { useRef, useEffect } from 'react'
import WomeImg from '../../images/screen-3/women.png'
import DeviceImg from '../../images/screen-3/kioskDevice.png'
import HamburgerImg from '../../images/screen-3/hamburger.png'
import CokeImg from '../../images/screen-3/coke.png'
import { useApp, useSection } from '../../hooks'
import CountUp from 'react-countup'
import { useLabelsContext } from '../../context/LabelsContext'
import * as S from './style'
import {
    motion,
    useScroll,
    useInView,
    useMotionValue,
    useAnimation,
} from 'framer-motion'

const KioskSection = ({ sectionRef, onVisible }) => {
    const { kioskSection: labels } = useLabelsContext()
    const isInViewOnce = useInView(sectionRef, { once: true })
    const controls = useAnimation()
    const controls2 = useAnimation()
    const { screenSize } = useApp()
    const { setRefs } = useSection({ sectionRef, onVisible })

    useEffect(() => {
        controls.start({
            y: [-100, 100],
            transition: {
                duration: 1.5,
                repeat: Infinity,
                repeatType: 'mirror',
            },
        })
        controls2.start({
            rotate: [0, 5],
            scale: [0.85, 0.95],
            transition: { duration: 1, repeat: Infinity, repeatType: 'mirror' },
        })
    }, [controls])

    return (
        <S.Wrapper ref={setRefs}>
            <S.Inner>
                <S.WomenImgContainer
                    style={{
                        transform: isInViewOnce ? 'none' : 'translateX(-200px)',
                        opacity: isInViewOnce ? 1 : 0,
                        transition:
                            'all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s',
                    }}
                >
                    {screenSize.innerWidth > 900 && (
                        <S.WomeImg src={WomeImg} className={'flipImg'} />
                    )}
                    <S.Content className={'textDirection'}>
                        <h2>{labels.title}</h2>
                        {labels.content}
                        <S.SizesContainer>
                            <S.SizesItem>
                                <CountUp end={isInViewOnce ? 21 : 0} />"
                            </S.SizesItem>
                            <S.SizesItem>
                                <CountUp end={isInViewOnce ? 22 : 0} />"
                            </S.SizesItem>
                            <S.SizesItem>
                                <CountUp end={isInViewOnce ? 27 : 0} />"
                            </S.SizesItem>
                            <S.SizesItem>
                                <CountUp end={isInViewOnce ? 32 : 0} />"
                            </S.SizesItem>
                        </S.SizesContainer>
                    </S.Content>
                </S.WomenImgContainer>
                <S.DeviceContainer>
                    <S.DeviceImg
                        src={DeviceImg}
                        style={{
                            transform: isInViewOnce
                                ? 'none'
                                : 'translateX(200px)',
                            opacity: isInViewOnce ? 1 : 0,
                            transition:
                                'all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s',
                        }}
                    />

                    <S.HamburgerImg
                        src={HamburgerImg}
                        // style={{
                        //     transform: isInView ? 'none' : 'translateY(-200px)',
                        //     opacity: isInView ? 1 : 0,
                        //     transition:
                        //         'all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.8s',
                        // }}
                        animate={controls2}
                    />
                    <S.CokeImg
                        // ref={ref}
                        src={CokeImg}
                        transition={{
                            type: 'spring',
                            damping: 3,
                            stiffness: 50,
                            restDelta: 0.001,
                        }}
                        animate={controls}
                    />
                </S.DeviceContainer>
            </S.Inner>
            {screenSize.innerWidth < 900 && <S.WomeImg src={WomeImg} />}
        </S.Wrapper>
    )
}

export default KioskSection

import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Wrapper = styled.div`
    min-width: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 0;

    @media (max-width: 900px) {
        display: none !important;
    }
`;
export const Button = styled(motion.button)`
    position: relative;
    z-index: 9;
    background: #161616;
    color: white;
    min-width: fit-content;
    width: 15%;
    max-width: 250px;
    min-height: 35px;
    font-size: 28px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-bottom: -25px;
    padding: 10px 20px;
    border-radius: 30px;
    border: 4px solid white;
    cursor: pointer;
    &:hover {
        background: rgb(54, 54, 54);
    }
`;

import React, { useState } from 'react'
import Field from './Field'
import * as S from './style'
import useContatForm from './useContatForm'
import useSection from '../../hooks/useSection'
import { ToastContainer, toast } from 'react-toastify'
import { useLabelsContext } from '../../context/LabelsContext'
import 'react-toastify/dist/ReactToastify.css'

const ContactSection = ({ sectionRef, language, onVisible }) => {
    const { setRefs } = useSection({ sectionRef, onVisible })

    const { contactSection: labels } = useLabelsContext()
    const getServiceOptions = () => {
        return labels.services.map((service) => ({
            label: service,
            value: service,
        }))
    }
    getServiceOptions()
    const FormSchema = [
        {
            name: 'name',
            label: labels.name,
            isRequired: true,
        },
        {
            name: 'phone',
            label: labels.phone,
            type: 'tel',
            isRequired: true,
        },
        {
            type: 'select',
            name: 'service',
            label: labels.select,
            options: getServiceOptions(),
        },
        {
            name: 'company',
            label: labels.company,
        },
        {
            name: 'comments',
            label: labels.comments,
            type: 'textarea',
        },
    ]

    const {
        formFields,
        sendEmail,
        handleFieldChange,
        isFormSent,
        isDirty,
        isSending,
    } = useContatForm(FormSchema, language)

    return (
        <S.Wrapper ref={setRefs}>
            <S.Inner>
                <S.FormContainer>
                    {!isFormSent && (
                        <>
                            {Object.keys(formFields).map((fieldKey, index) => (
                                <Field
                                    key={index}
                                    value={formFields[fieldKey].value}
                                    label={formFields[fieldKey]?.label}
                                    options={formFields[fieldKey]?.options}
                                    onChange={handleFieldChange}
                                    name={fieldKey}
                                    type={formFields[fieldKey]?.type}
                                    isValid={formFields[fieldKey]?.isValid}
                                    disabled={isSending}
                                />
                            ))}
                            <S.SubmitButton
                                tpye="button"
                                onClick={() => sendEmail(formFields)}
                                disabled={isSending}
                            >
                                {isSending && (
                                    <>
                                        <div className={'loader'}></div>
                                        {labels.sending}...
                                    </>
                                )}
                                {!isSending && <>Send</>}
                            </S.SubmitButton>
                        </>
                    )}
                    {isFormSent && (
                        <S.SuccessMessage>
                            {labels.contactShortly}
                            <u>{formFields.name.value}</u> ! <br />
                            {labels.contactShortly}
                        </S.SuccessMessage>
                    )}
                    <ToastContainer />
                </S.FormContainer>
                <S.Content className="textDirection">
                    <h2>{labels.title}</h2>
                    {labels.content}
                </S.Content>
            </S.Inner>
        </S.Wrapper>
    )
}

export default ContactSection

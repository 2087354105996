import React, { memo } from 'react';
import { useLabelsContext } from '../../../context/LabelsContext';
import { useApp } from '../../../hooks';
import * as S from './style';

const JoinNow = ({ scrollToSection, isAlwayVisible, marginBlock }) => {
    const labels = useLabelsContext();
    const handleContactClick = () => {
        scrollToSection('contactUs');
    };

    return (
        <S.Wrapper isAlwayVisible={isAlwayVisible}>
            <S.Button
                className={'textDirection'}
                onClick={() => handleContactClick()}
                marginBlock={marginBlock}
            >{`${labels.joinNow} >>`}</S.Button>
        </S.Wrapper>
    );
};
export default memo(JoinNow);

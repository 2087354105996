import { useRef, useState, useEffect, useCallback } from 'react';

function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
}

const useApp = () => {
    const tech = useRef();
    const welcome = useRef();
    const maxKiosk = useRef();
    const WelcomeSection = useRef();
    const web = useRef();
    const eApp = useRef();
    const maxMenuTab = useRef();
    const contactUs = useRef();
    const tabPos = useRef();
    const kds = useRef();
    const iPadWaiters = useRef();
    const greenReceipts = useRef();
    const qr = useRef();

    const refs = {
        welcome,
        tech,
        maxKiosk,
        WelcomeSection,
        web,
        eApp,
        maxMenuTab,
        contactUs,
        tabPos,
        kds,
        iPadWaiters,
        greenReceipts,
        qr,
    };
    const [screenSize, setScreenSize] = useState(getWindowSize());

    const scrollToSection = useCallback((key) => {
        if (!refs[key].current) return;
        const scrollOptions = {
            top: key === 'tech' ? 0 : refs[key].current.offsetTop - 80,
            left: 0,
            behavior: 'smooth',
        };
        window.scroll(scrollOptions);
    });

    useEffect(() => {
        function handleWindowResize() {
            setScreenSize(getWindowSize());
        }
        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);
    return {
        screenSize,
        refs,
        scrollToSection,
    };
};

export default useApp;

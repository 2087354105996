import styled from 'styled-components';

export const Wrapper = styled.div`
    margin: 0px auto;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    position: relative;
    padding-block-start: 113.5px;

    .Toastify {
        z-index: 999999999;
    }

    * {
    }
    .textDirection {
        ${({ isRTL }) => isRTL && ` direction:rtl;`}
    }
`;

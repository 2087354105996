import styled from 'styled-components'

export const Wrapper = styled.div`
    box-sizing: border-box;
    width: 100%;
    background: #38ce1a;
    min-height: fit-content;
    color: white;
    padding-block: 5vw;
    @media (max-width: 1200px) {
        padding-block: 35px;
    }
`
export const Inner = styled.div`
    width: 93%;
    margin: 0px auto;
    max-width: 1600px;
    min-width: 1200px;
    position: relative;
    display: flex;
    @media (max-width: 1200px) {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        padding-inline: 15px;
        box-sizing: border-box;
    }

    @media (max-width: 850px) {
        flex-direction: column-reverse;
    }
`

export const FormContainer = styled.div`
    width: 40%;
    min-width: 460px;
    display: flex;
    flex-wrap: wrap;
    gap: 0 15px;
    & > * {
        flex: 1 0 40%;
    }
    @media (max-width: 850px) {
        width: 100%;
        margin-block-start: 20px;
        box-sizing: border-box;
    }
    & > div:not(:last-child, :nth-child(-2)) {
        margin-block-end: 15px;
    }
    @media (max-width: 480px) {
        flex-direction: column;
        min-width: 100%;

        & > * {
            flex: 1;
        }
    }

    & .rmsc .options {
        padding: 0;
    }
`
export const SubmitButton = styled.button`
    background: black;
    border-radius: 35px;
    font-size: 34px;
    font-weight: 900;
    min-width: 300px;
    color: white;
    padding: 9px 25px;
    min-width: 100%;
    cursor: pointer;
    transition: all 0.5s ease;
    border: 0;
    outline: 0;
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;

    &:disabled {
        background: #d8cece;
        color: #bebebe;
    }
    &:hover:not(:disabled) {
        background: white;
        color: black;
    }

    & .loader,
    & .loader:after {
        border-radius: 50%;
        width: 20px;
        height: 20px;
    }
    & .loader {
        margin: 0;
        font-size: 10px;
        position: relative;
        text-indent: -9999em;
        border-top: 5px solid #a9a9a933;
        border-right: 5px solid #a9a9a933;
        border-bottom: 5px solid #a9a9a933;
        border-left: 5px solid #c1c1c1;
        -webkit-transform: translateZ(0);
        -ms-transform: translateZ(0);
        transform: translateZ(0);
        -webkit-animation: load8 1.1s infinite linear;
        animation: load8 1.1s infinite linear;
    }
    @-webkit-keyframes load8 {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
    @keyframes load8 {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
`

export const Content = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 36px;
    color: black;
    gap: 8px;

    & h2 {
        margin: 0;
        line-height: 100%;
        font-size: 52px;
        font-weight: 900;
    }

    @media (max-width: 850px) {
        font-size: 28px;
        & h2 {
            line-height: 28px;
            font-size: 42px;
        }
    }
`
export const SuccessMessage = styled.div`
    color: black;
    font-size: 34px;
    text-align: center;
`

import React, { memo } from 'react';
import { useLabelsContext } from '../../../context/LabelsContext';
import { useApp } from '../../../hooks';
import * as S from './style';

const DesktopJoinNow = ({ scrollToSection }) => {
    const labels = useLabelsContext();
    const handleContactClick = () => {
        scrollToSection('contactUs');
    };

    return (
        <S.Wrapper>
            <S.Button
                className={'textDirection'}
                onClick={() => handleContactClick()}
                animate={{
                    scale: [0.95, 1.1],
                }}
                transition={{
                    repeat: Infinity,
                    repeatType: 'reverse',
                    duration: 1,
                }}
            >{`${labels.joinNow} >>`}</S.Button>
        </S.Wrapper>
    );
};
export default memo(DesktopJoinNow);

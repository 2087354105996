import React, { useState, useEffect } from 'react'
import * as S from './style'
import { MultiSelect } from 'react-multi-select-component'

const Field = ({
    type = 'text',
    label = '',
    value = '',
    onChange,
    name,
    isValid,
    options = [],
    hasError = false,
    disabled,
}) => {
    const [selected, setSelected] = useState([])

    useEffect(() => {
        onChange(name, selected)
    }, [selected])

    const handleCahge = (e) => {
        onChange(name, e.target.value)
    }
    const handleSelectChange = (newValue = []) => {
        // onChange(name, newValue)
    }

    const getField = (fieldType) => {
        switch (fieldType) {
            case 'textarea':
                return (
                    <S.TextArea
                        name={name}
                        placeholder={label}
                        value={value}
                        type={type}
                        onChange={handleCahge}
                        disabled={disabled}
                    />
                )
            case 'select':
                return (
                    <MultiSelect
                        overrideStrings={{
                            allItemsAreSelected: 'All items are selected.',
                            clearSearch: 'Clear Search',
                            clearSelected: 'Clear Selected',
                            noOptions: 'No options',
                            search: 'Search',
                            selectAll: 'Select All',
                            selectAllFiltered: 'Select All (Filtered)',
                            selectSomeItems: `${label}...`,
                            create: 'Create',
                        }}
                        hasSelectAll={false}
                        options={options}
                        value={selected}
                        onChange={setSelected}
                        labelledby="1212"
                        disabled={disabled}
                    />
                )

            default:
                return (
                    <S.Input
                        name={name}
                        placeholder={label}
                        value={value}
                        type={type}
                        onChange={handleCahge}
                        disabled={disabled}
                    />
                )
        }
    }

    return (
        <S.Container hasError={!isValid} className={'textDirection'}>
            {getField(type)}
        </S.Container>
    )
}

export default Field

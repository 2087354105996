import React from 'react';
import { useSection, useContentDialog } from '../../hooks';
import { useLabelsContext } from '../../context/LabelsContext';
import { useSectionContentContext } from '../../context/SectionContentContext';
import { useInView } from 'framer-motion';
import ReadMoreLink from '../common/ReadMoreLink/ReadMoreLink';
import DesktopJoinNow from '../common/DesktopJoinNow';
import JoinNow from '../common/JoinNow';
import ReceiptImg from '../../images/section-10/receiptImg.png';
import IconGoogle from '../../images/section-10/icon-google.png';
import IcoHeart from '../../images/section-10/icon-heart.png';
import IcoInstagram from '../../images/section-10/icon-instagram.png';
import IcoFacebook from '../../images/section-10/icon-like.png';
import * as S from './style';

const ReceiptsSection = ({ sectionRef, onVisible, scrollToSection }) => {
    const { greenReceipts } = useSectionContentContext();
    const { ContentDialog, onOpenDialog } = useContentDialog({
        content: greenReceipts?.content,
        scrollToSection,
    });
    const { setRefs } = useSection({ sectionRef, onVisible });

    const { receiptsSection: labels } = useLabelsContext();
    const isInViewOnce = useInView(sectionRef, { once: true });

    return (
        <S.Wrapper bgColor={'#ecfade'} ref={setRefs}>
            <S.Inner>
                <S.ImgContainer
                    style={{
                        transform: isInViewOnce
                            ? 'translateX(0)'
                            : 'translateX(-500px)',
                        opacity: isInViewOnce ? 1 : 0,
                        transition:
                            'all 0.5s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s',
                    }}
                >
                    <S.ReceiptsImg src={ReceiptImg} alt="Receipts" />
                    <S.Icon
                        animate={{
                            scale: [0.9, 1.05],
                            rotate: [0, 15],
                        }}
                        transition={{
                            repeat: Infinity,
                            repeatType: 'reverse',
                            duration: 1,
                        }}
                        className={'instagram'}
                        src={IcoInstagram}
                    />
                    <S.Icon
                        animate={{
                            scale: [0.9, 1.05],
                            rotate: [0, 15],
                        }}
                        transition={{
                            repeat: Infinity,
                            repeatType: 'reverse',
                            duration: 0.5,
                        }}
                        className={'facebook'}
                        src={IcoFacebook}
                    />
                    <S.Icon
                        animate={{
                            scale: [0.95, 1],
                            // rotate: [0, -5, 5, 15],
                        }}
                        transition={{
                            repeat: Infinity,
                            delay: 0.5,
                            repeatType: 'reverse',
                            duration: 0.5,
                        }}
                        className={'heart'}
                        src={IcoHeart}
                    />
                    <S.Icon
                        animate={{
                            scale: [0.97, 1, 1.03, 1.06],
                            rotate: [-10, 0, 5, 0],
                        }}
                        transition={{
                            repeat: Infinity,
                            repeatType: 'reverse',
                            duration: 1,
                        }}
                        transition={{
                            repeat: Infinity,
                            delay: 0.5,
                            repeatType: 'reverse',
                            duration: 0.5,
                        }}
                        className={'google'}
                        src={IconGoogle}
                    />
                </S.ImgContainer>
                <S.Content
                    className={'textDirection'}
                    style={{
                        transform: isInViewOnce
                            ? 'translateX(0)'
                            : 'translateX(500px)',
                        opacity: isInViewOnce ? 1 : 0,
                        transition:
                            'all 0.5s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s',
                    }}
                >
                    <S.Title>
                        {labels.title}
                        {` `}
                        <span>{labels.secondTitle}</span>
                    </S.Title>
                    <S.Text>
                        {labels.content}
                        <ReadMoreLink onClick={onOpenDialog} />
                    </S.Text>
                    <JoinNow scrollToSection={scrollToSection} />
                </S.Content>
            </S.Inner>
            <DesktopJoinNow scrollToSection={scrollToSection} />
            <ContentDialog />
        </S.Wrapper>
    );
};

export default ReceiptsSection;

const heb = () => {
    return {
        title: 'מקסשופ | maxshop לשדרג את המכירות שלך',
        description: `יוצרים את חווית הרכישה הטובה ביותר עבור עסקים ולקוחות הקצה
        באתר מכירות אישי, בעמדת קיוסק להזמה עצמית ובקופת ענן מתקדמת
        הידע שצברנו בעולם המסחר האלקטרוני מבדיל אותנו מכל מה שהכרת
        שדרגו את המכירות שלכם עם מקסשופ עוד היום`,
        keywords:
            'עמדת הזמנות, עמדת קיוסק, קיוסק למסעדה, קופה בענן, טאבלט מלצרים, קופת ענן, אתר הזמנות, אתר הזמנות מזון מהיר,טאבלט עם תפריט, חנות וירטואלית, אפליקציית הזמנות, עמדת שירות עצמי, עמדת שירות מזון מהיר, קופה למסעדה, קופה עם מפה לשולחנות, חשבוניות באסמס, מסך kds, מערכת חשבונית דיגיטלית ירוקה, אייפד למלצרים, מערכת קיו אר להזמנות בעסק, חשבונית באסמס, חשבונית בעמדת קיוסק, חשבונית בעמדת קיוסק, קיוסק עם תשלום במזומן',
        joinNow: 'הצטרפו עכשיו',
        menuItems: {
            tech: 'אודות מקסשופ',
            maxKiosk: 'עמדת קיוסק',
            web: 'אתר מכירות',
            eApp: 'אפליקציית מכירות',
            maxMenuTab: 'תפריט טאבלט לרכישה',
            greenReceipts: 'חשבונית ירוקה',
            contactUs: 'יצירת קשר',
        },
        moreMenuItems: {
            tech: 'אודות מקסשופ',
            maxKiosk: 'עמדת קיוסק',
            web: 'אתר מכירות',
            eApp: 'אפליקציית מכירות',
            maxMenuTab: 'תפריט טאבלט לרכישה',
            iPadWaiters: 'אייפד למלצרים',
            tabPos: 'קופה בענן',
            kds: 'KDS',
            greenReceipts: 'חשבונית ירוקה',
            qr: 'קוד QR',
            contactUs: 'יצירת קשר',
        },
        readMore: 'קראו עוד',
        contactSection: {
            services: [
                'maxkiosk',
                'webDelivery',
                'appDelivery',
                'menuOrder',
                'maxshopPos',
                'kds',
                'qr',
                'green receipts',
                'iPad waiters',
            ],
            title: 'צור קשר',
            content: 'הצטרף לmaxShop עוד היום',
            name: 'שם',
            phone: 'טלפון',
            select: 'בחר',
            company: 'חברה',
            comments: 'הערות',
            thankYou: 'תודה',
            contactShortly: 'ניצור עמך קשר בהקדם',
            sending: 'שולח',
        },
        WelcomeSection: {
            content:
                'אנו מזמינים אותך לחוות\nאת פלטפורמת השירות העצמי\nהמתקדמת ביותר\nמבית maxshop\n',
            contactButton: 'לפרטים והצטרפות',
        },
        contentSection: {
            title: 'שדרג את המכירות שלך עם maxshop',
            text: 'שילוב קסם של ניסיון מעשי וחשיבה על כל פרט במערכת הופך עבורך יותר גולשים לרוכשים.',
            reasons: {
                reason1: {
                    title: 'התאמה מלאה',
                    content:
                        'לכל סוגי המכשירים ושימוש בשרתי הענן עם הביצועים הטובים ביותר',
                },
                reason2: {
                    title: 'ניהול פשוט ויעיל',
                    content:
                        'הכל במקום אחד עם שליטה ב- 360° המאפשרת לך להמשיך בניהול העסק',
                },
                reason3: {
                    title: 'אינספור מודולים',
                    content:
                        'חשבנו על הכל בכדי להפוך את מקסשופ \nלפלטפורמה היחידה שהעסק שלך צריך \n',
                },
                reason4: {
                    title: 'חווית משתמש ידידותית',
                    content: 'הלקוחות שלך יתאהבו\nוירצו לחזור ולהזמין שוב ושוב',
                },
            },
            joinUs: 'רוצה להצטרף אלינו?',
            contactUs: 'צור קשר',
        },
        kioskSection: {
            title: 'maxshop kiosk',
            content:
                'עמדת הזמנות שעובדת - ללא הפסקה!\nללא צורך בעובד מערכת הקיוסק\nמחייבת את הלקוח באשראי ומזומן, מציעה אפסיילים\nמוציאה הזמנות למטבח\nייחודי במקסשופ! - קבלת הזמנה ב- sms עם מידע שיווקי\n',
        },
        ecommerceSection: {
            title: 'maxshop e-commerce',
            content:
                'אתר מכירות אישי עם חווית רכישה מושלמת אשר נראת ומדברת כמו העסק שלך',
        },
        appSection: {
            title: 'delivery app',
            content:
                'להיות תמיד בכיס של הלקוח\nעם אפליקציה אישית באפל ו-אנדרואיד',
        },
        menuTabSection: {
            title: 'maxshop digital menu',
            content:
                'הטאבלט של מקסשופ מאפשר לראות תפריט\nוגם להזמין עצמאית!\nמבלי לחכות למלצר\nעם בחירת שולחן ובניית מנה\nכל הזמנה יוצאת ישירות למטבח\n',
        },
        tabPosSection: {
            title: 'maxshop cloud pos',
            content:
                'מעכשיו הכל במקום אחד!\nקופה יוקרתית בענן\nהמאפשרת ניהול פשוט עם אפליקציה\nוחיבור מושלם לכל אחד מהמוצרים האחרים של מקסשופ\n',
        },
        kdsSection: {
            title: 'maxshop KDS screen',
            content:
                'נתונים למטבח בזמן אמת במקום מרוכז\nלהגיב במהירות לשינויים בביקוש גם בשיא הלחץ\nלחסוך לך עלויות רבות של טעויות\nובעיקר לצבור מידע השווה לעסק שלך המון כסף\n',
        },
        waitersSection: {
            title: 'maxshop waiters tablet',
            content:
                'מעכשיו המלצרים שלך יכולים לקבל הזמנות במהירות ובדייקנות\nמה שיכול לשפר את יעילות השירות הכוללת.\nעם האייפד של מקסשופ, ניתן לקבל הזמנות ליד השולחן ולשלוח ישירות למטבח,\nלהפחית את הצורך בהזמנות בכתב יד ולהקטין את הסבירות לטעויות.',
        },
        receiptsSection: {
            title: 'maxshop Digital',
            secondTitle: 'green invoices',
            content:
                'מידע על הלקוחות שלך הוא כוח עצום עבור העסק שלך!\nזו ההזדמנות שלך לתת ערך מוסף ללקוח\nוגם להצמיח את העסק שלך\nבאמצעות צבירת מידע של לקוחות שביצעו רכישה\nהעסק שלך יכול לשווק את המוצרים לקנייה חוזרת',
        },
        paymentSection: {
            title: 'maxshop QR payment',
            content:
                'הזמנת אוכל במסעדות ובעסק מזון מהיר\nבאמצעות קוד QR של מקסשופ להזמנה ולתשלום מהיר\nהלקוחות שלך יכולים לסרוק את הקוד עם מצלמת הסמארטפון\nולגשת לתפריט המכיל תמונות ומידע המונע טעויות',
        },
        header: {
            login: 'התחברות',
        },
        loginPage: {
            title: 'התחברות מנהלים',
            content: 'יש למלא את השדות:',
            domain: 'כתובת האתר',
            userName: 'שם משתמש',
            password: 'סיסמא',
            rememberMe: 'זכרו אותי',
            login: 'התחברות',
            onLogin: 'בהתחברותך לממשק הנך מסכים',
            terms: 'לתנאי השימוש',
            copyRight: '© Copyright maxshop systems LTD. All rights reserved.',
            isRequired: '* שדה חובה',
            error: 'לא נמצא מנהל עם הפרטים שהזנת',
            loginPage: 'התחברות מנהלים',
        },
    };
};
export default heb;

import React, { useEffect, useState } from 'react';
import Dialog from '../components/common/Dialog';

const useContentDialog = ({ content, scrollToSection = () => {} }) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [dialogContent, setDialogContent] = useState(content);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        if (content) {
            setDialogContent(content);
            setIsLoading(false);
        } else {
            setIsLoading(true);
        }
    }, [content]);

    const onOpenDialog = () => {
        setIsDialogOpen(true);
    };
    const onCloseDialog = () => {
        setIsDialogOpen(false);
    };

    const handleScrollToSection = (targetSection) => {
        setIsDialogOpen(false);
        scrollToSection(targetSection);
    };

    const ContentDialog = () => (
        <Dialog
            isOpen={isDialogOpen}
            content={content}
            onClose={onCloseDialog}
            onScrollToSection={handleScrollToSection}
        ></Dialog>
    );

    return {
        isDialogOpen,
        isLoading,
        onOpenDialog,
        dialogContent,
        onCloseDialog,
        ContentDialog,
    };
    return { is: true };
};

export default useContentDialog;

import React from 'react'
import { useLabelsContext } from '../../context/LabelsContext'
import WomanImg from '../../images/login/woman.png'
import WomanImgMobile from '../../images/login/woman-mobile.png'
import LogoImg from '../../images/logo.png'
import AppStoreImg from '../../images/login/appstore.png'
import GooglePlayImg from '../../images/login/googleplay.png'
import Field from './Field'
import Checkbox from '@mui/material/Checkbox'
import useLogin from './useLogin'
import Select from 'react-select'
import { useApp } from '../../hooks/'
import * as S from './style'
import { useSearchParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'

const Login = ({ language }) => {
    const { loginPage: labels } = useLabelsContext()
    const [searchParams] = useSearchParams()
    const domain = searchParams.get('domain')
    const error = searchParams.get('error')
    const { screenSize } = useApp()
    const WomanImgDynamic =
        screenSize.innerWidth < 875 ? WomanImgMobile : WomanImg
    const formSchema = [
        {
            name: 'domain',
            label: labels.domain,
            type: 'domain',
            value: domain ?? '',
            isRequired: true,
        },
        {
            name: 'username',
            type: 'text',
            value: '',
            label: labels.userName,
            isRequired: true,
        },
        {
            name: 'password',
            label: labels.password,
            type: 'password',
            value: '',
            isRequired: true,
        },
        {
            name: 'remember',
            label: labels.rememberMe,
            type: 'checkbox',
            isRequired: false,
            value: false,
        },
    ]
    const { formFields, handleFieldChange, isSending, handleSubmit } =
        useLogin(formSchema)
    const isRTL = language === 'heb' ? true : false

    return (
        <S.Wrapper isRTL={isRTL}>
            <Helmet>
                <title>{labels.loginPage}</title>
            </Helmet>
            <S.Inner>
                <S.WomanImg src={WomanImgDynamic} />
                <S.Content>
                    <Link to="/">
                        <S.Logo src={LogoImg} />
                    </Link>
                    <S.Text>
                        <h2>{labels.title}</h2>
                        <p>{labels.content}</p>
                    </S.Text>
                    <S.FormContainer
                        onSubmit={handleSubmit}
                        action={'/panel.php?act=login&op=do_login'}
                        method={'POST'}
                    >
                        {error == 1 && (
                            <S.ErrorMessage>{labels.error}</S.ErrorMessage>
                        )}
                        {Object.keys(formFields).map((fieldName) => {
                            if (formFields[fieldName].type !== 'checkbox') {
                                return (
                                    <S.Relative>
                                        <Field
                                            name={formFields[fieldName].name}
                                            type={formFields[fieldName].type}
                                            label={formFields[fieldName].label}
                                            value={formFields[fieldName].value}
                                            onChange={handleFieldChange}
                                            isValid={
                                                formFields[fieldName]?.isValid
                                            }
                                            disabled={isSending}
                                            component={
                                                formFields[fieldName].type ===
                                                    'domain' && (
                                                    <Select
                                                        name={'domain_tld'}
                                                        defaultValue={
                                                            DOMAIN_OPTIONS[0]
                                                        }
                                                        onChange={(e) => {}}
                                                        options={DOMAIN_OPTIONS}
                                                    />
                                                )
                                            }
                                        />
                                        {!formFields[fieldName]?.isValid && (
                                            <S.Error
                                                className={'textDirection'}
                                            >
                                                {labels.isRequired}
                                            </S.Error>
                                        )}
                                    </S.Relative>
                                )
                            }
                        })}
                        <S.CheckBoxContainer>
                            <label className={'textDirection'}>
                                <Checkbox
                                    name="remember"
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            handleFieldChange('remember', 1)
                                        } else {
                                            handleFieldChange('remember', 0)
                                        }
                                    }}
                                    value={formFields['remember'].value}
                                    checked={!!formFields['remember'].value}
                                />
                                {labels.rememberMe}
                            </label>
                        </S.CheckBoxContainer>
                        <S.SubmitButton type={'submit'}>
                            {labels.login}
                        </S.SubmitButton>
                        <S.Terms className="textDirection">
                            {labels.onLogin}
                            <a href="#">{labels.terms}</a>
                            {language !== 'heb' && labels.use}
                        </S.Terms>
                    </S.FormContainer>
                    <S.Applications>
                        {false && (
                            <S.AppImg
                                src={AppStoreImg}
                                onClick={() => (window.location.href = '')}
                            />
                        )}
                        <S.AppImg
                            src={GooglePlayImg}
                            onClick={() =>
                                (window.location.href =
                                    'https://play.google.com/store/apps/details?id=il.co.maxshop.admin')
                            }
                        />
                    </S.Applications>
                </S.Content>
            </S.Inner>
            <S.Footer>
                <p>© Copyright maxshop systems LTD. All rights reserved.</p>
            </S.Footer>
        </S.Wrapper>
    )
}

export default Login

const DOMAIN_OPTIONS = [
    {
        value: '.maxshop.co.il',
        label: '.maxshop.co.il',
    },
    {
        value: '.co.il',
        label: '.co.il',
    },
    {
        value: '.org.il',
        label: '.org.il',
    },
    {
        value: '.org',
        label: '.org',
    },
    {
        value: '.net',
        label: '.net',
    },
    {
        value: '.biz',
        label: '.biz',
    },
]

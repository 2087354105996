import styled from 'styled-components'

export const Input = styled.input`
    width: 100%;
    min-height: 100%;
    background: none;
    border: 0;
    outline: 0;
    display: flex;
    font-size: 22px;
    color: black;
    box-sizing: border-box;
    border-radius: 35px;
    text-align: center;
    @media (max-width: 740px) {
        min-height: 40px;
    }
`
export const TextArea = styled.textarea`
    width: 100%;
    min-height: 90px;
    background: none;
    border: 0;
    outline: 0;
    display: flex;
    font-size: 22px;
    padding-block-start: 8px;
    color: black;
    ::-webkit-resizer {
        display: none;
    }
`
export const Container = styled.div`
    width: 100%;
    height: fit-content;
    min-height: 54px;
    display: flex;
    background: white;
    border: 2px solid white;
    border-color: white;
    transition: all 0.3s ease-out;
    box-sizing: border-box;
    border-radius: 35px;
    padding-inline: 15px;
    position: relative;
    & .dropdown-container {
        background: none !important;
        border: 0 !important;
        width: 100% !important;
        color: black;
        font-size: 22px;
        outline: 0 !important;
        min-height: 100%;
        display: flex;
        align-items: center;
        box-shadow: 0 0 0 !important;
    }
    & .dropdown-heading {
        min-height: 100%;
    }
    & .multi-select {
        position: absolute;
        width: calc(100% - 15px);
        height: 100%;
        outline: 0 !important;
    }

    & .gray {
        color: #757575;
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px white inset !important;
    }

    @media (max-width: 740px) {
        min-height: 40px;
    }
`

export const Inner = styled.div`
    min-width: fit-content;
    margin-inline-end: -15px;
    .css-13cymwt-control {
        background: #d7d7d7 !important;
        outline: 0 !important;
        border: 0;
    }
    .css-t3ipsp-control {
        background: #d7d7d7 !important;
        outline: 0 !important;
        border: 0 !important;
        box-shadow: 0 0 0 !important;
    }
    .css-b62m3t-container {
        min-height: 100%;
        display: flex;
        font-size: 22px;
    }
    .css-b62m3t-container .css-13cymwt-control,
    .css-b62m3t-container .css-t3ipsp-control {
        padding-inline: 15px 25px;
        border-radius: 0;
        border-end-end-radius: 35px;
        border-start-end-radius: 35px;
        min-height: 100%;
    }
`

import styled from 'styled-components';

export const Wrapper = styled.div`
    min-width: 100%;
    display: ${({ isAlwayVisible }) => (isAlwayVisible ? 'flex' : 'none')};
    justify-content: center;
    @media (max-width: 900px) {
        display: flex;
    }
`;
export const Button = styled.div`
    background: #161616;
    color: white;
    margin-block: ${({ marginBlock }) => marginBlock ?? '10px'};
    font-size: 22px;
    font-weight: 500;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 10px 20px;
    border-radius: 30px;
    border: 2px solid white;
    cursor: pointer;
`;

import React from 'react';
import logo1 from '../../images/partners/1.png';
import logo2 from '../../images/partners/2.png';
import logo3 from '../../images/partners/3.png';
import logo4 from '../../images/partners/4.png';
import logo5 from '../../images/partners/5.png';
import logo6 from '../../images/partners/6.png';
import { useSection } from '../../hooks';
import * as S from './style';

const VideoSection = ({ sectionRef, onVisible }) => {
    const { setRefs } = useSection({ sectionRef, onVisible });

    return (
        <S.Wrapper ref={setRefs}>
            <S.Inner>
                <S.Video
                    src={`https://www.youtube.com/embed/eovMACS7l8A?rel=0`}
                    frameborder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                />
            </S.Inner>
            <S.Partners>
                <S.Inner>
                    <S.ImgItem src={logo1} />
                    <S.ImgItem src={logo2} />
                    <S.ImgItem src={logo3} />
                    <S.ImgItem src={logo4} />
                    <S.ImgItem src={logo5} />
                    <S.ImgItem src={logo6} />
                </S.Inner>
            </S.Partners>
        </S.Wrapper>
    );
};

export default VideoSection;

import React from 'react';
import * as S from './style';
import { useLabelsContext } from '../../../context/LabelsContext';

const ReadMoreLink = ({ onClick }) => {
    const { readMore } = useLabelsContext();
    return (
        <S.Link onClick={onClick}>
            {readMore} {`>>>`}
        </S.Link>
    );
};

export default ReadMoreLink;

import styled from 'styled-components';

export const Wrapper = styled.div`
    background: black;
    width: 100%;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-x: auto;
    ::-webkit-scrollbar {
        display: none; /* for Chrome, Safari, and Opera */
    }

    border-top: 2px solid rgba(255, 255, 255, 0.7);
`;
export const Inner = styled.ul`
    height: 35px;
    min-width: fit-content;
    width: auto;
    -ms-overflow-style: none;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    justify-content: center;
`;
export const Item = styled.li`
    width: auto;
    min-height: 100%;
    height: 100%;
    padding: 0 15px;
    display: flex;
    align-items: center;
    font-size: 18px;
    color: black;
    white-space: nowrap;
    font-weight: ${({ isActive }) => (isActive ? 700 : 500)};
    color: ${({ isActive }) => (isActive ? '#00ff48' : 'white')};
    cursor: pointer;
    &:hover {
        color: #00ff48;
    }
    transition: color 0.3s ease-in-out;
`;

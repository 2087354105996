import styled from 'styled-components'

export const Wrapper = styled.div`
    box-sizing: border-box;
    width: 100%;
    background: white;
    min-height: 500px;
    padding-inline: ${({ pagePaddinInline }) => pagePaddinInline};
    color: white;
    margin-block: 5vw;
    padding-block-start: 2%;
    box-shadow: 2.939px 4.045px 39.69px 9.31px rgba(0, 0, 0, 0.11);
    position: relative;
    @media (max-width: 840px) {
        padding-block: 20px;
        margin-block: 35px;
    }
    @media (max-width: 600px) {
        min-height: fit-content;
        padding-block-end: 30px;
    }
`
export const Inner = styled.div`
    width: 93%;
    margin: 0px auto;
    max-width: 1600px;
    min-width: 1200px;
    position: relative;
    display: flex;

    @media (max-width: 1200px) {
        min-width: 100%;
        max-width: 100%;
        padding-inline: 15px;
        box-sizing: border-box;
    }
    @media (max-width: 900px) {
        flex-wrap: wrap;
        flex-direction: column-reverse;
        gap: 30px;
    }
    @media (max-width: 600px) {
        flex-wrap: wrap;
        justify-content: center;
    }
`
export const ImgsContainer = styled.div`
    width: 48%;
    /* min-width: fit-content; */
    max-width: 800px;
    margin-inline-start: 8%;
    position: relative;
    @media (max-width: 1500px) {
        max-width: 518px;
    }
    @media (max-width: 900px) {
        margin-inline-start: 0;
        margin: 0px auto;
        margin-block-end: -15px;
    }
    @media (max-width: 600px) {
        margin-block-end: -24px;
        min-width: 300px;
        padding-left: 45px;
    }
`
export const Laptop = styled.img`
    width: 100%;
`
export const Iphone = styled.img`
    position: absolute;
    width: 50%;
    top: -12%;
    left: -31%;
    @media (max-width: 900px) {
        top: 0;
        /* position: relative;
        top: 0;
        left: 0;
        width: 295px; */
    }
    @media (max-width: 600px) {
        margin-left: 35px;
    }
`
export const Fruit1 = styled.img`
    position: absolute;
    top: -1%;
    right: -3%;
    width: 24%;
    @media (max-width: 840px) {
        top: -25px;
    }
`
export const Fruit2 = styled.img`
    position: absolute;
    bottom: 9%;
    left: -22%;
    width: 24%;
    @media (max-width: 900px) {
        bottom: 35px;
        left: -42px;
    }
`
export const Fruit3 = styled.img`
    position: absolute;
    right: 18%;
    bottom: -3%;
    width: 12.7%;
    @media (max-width: 840px) {
        bottom: -25px;
        min-width: 80px;
    }
    @media (max-width: 600px) {
        bottom: -22px;
        z-index: 9;
        right: 35%;
    }
`
export const Content = styled.div`
    display: flex;
    flex-direction: column;
    color: black;
    font-size: 36px;
    flex: 1;
    justify-content: center;
    & h2 {
        font-size: 41px;
        margin: 0;
        font-weight: 700;
    }
    & p {
        margin: 0;
        white-space: pre-wrap;
    }

    @media (max-width: 1200px) {
        font-size: 28px;

        & h2 {
            font-size: 36px;
        }
    }
    @media (max-width: 900px) {
        width: 100%;
        margin: 0px auto;
        max-width: 550px;
        gap: 15px;
        & h2 {
            text-align: center;
        }
    }
    @media (max-width: 600px) {
        min-width: 100%;
        text-align: center;
        & h2 {
            line-height: 100%;
            margin-block-end: 10px;
        }
    }
`

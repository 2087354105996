import React from 'react'
import Img404 from '../../images/404.png'
import { Link } from 'react-router-dom'
import * as S from './style'
const PageNotFound = () => {
    return (
        <S.Wrapper>
            <Link to={'/'}>
                <img src={Img404} />
            </Link>
        </S.Wrapper>
    )
}

export default PageNotFound

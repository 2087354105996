import React, { useState, useEffect } from 'react'
import { useLabelsContext } from '../../context/LabelsContext'
import { useApp } from '../../hooks'
import Header from '../Header'

import * as S from './style'
import { motion } from 'framer-motion'

const StikcyHeader = (props) => {
    const [offSetTop, setOffsetTop] = useState(0)
    const { screenSize } = useApp()
    const isMenuSticky = +offSetTop > screenSize.innerHeight

    useEffect(() => {
        const onScroll = () => setOffsetTop(window.pageYOffset)
        // clean up code
        window.removeEventListener('scroll', onScroll)
        window.addEventListener('scroll', onScroll, { passive: true })
        return () => window.removeEventListener('scroll', onScroll)
    }, [])
    return <Header {...props} isVisible={isMenuSticky} isMenuSticky={true} />
}

export default StikcyHeader

import styled from 'styled-components'

export const Button = styled.button`
    position: relative;
    height: 45px;
    z-index: 5;
    background: none;
    outline: 0;
    border: 0;

    & div {
        position: relative;
        width: 30px;
        height: 5px;
        border-radius: 2px;
        background-color: white;
        margin-top: 5px;
        transition: all 0.3s ease-in-out;
    }

    &.active .top-bun {
        transform: rotate(-45deg);
        margin-top: 25px;
    }
    &.active .bottom-bun {
        opacity: 0;
        transform: rotate(45deg);
    }
    &.active .meat {
        transform: rotate(45deg);
        margin-top: -7px;
    }
`

import styled from 'styled-components'

export const Wrapper = styled.div`
    background: #161616;
    position: relative;
    min-height: 100%;
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        width: 500px;
        max-width: 90%;
        margin: 0px auto;
        display: block;
    }
`

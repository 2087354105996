import styled from 'styled-components';

export const Wrapper = styled.div`
    box-sizing: border-box;
    width: 100%;
    background: white;
    min-height: 800px;
    color: white;
    padding-block: 4.5% 0;
    min-height: 500px;
    @media (max-width: 1480px) {
        padding-block: 6.5vw 0;
    }
    @media (max-width: 1150px) {
        padding-block: 40px 0;
        min-height: fit-content;
    }
`;
export const Inner = styled.div`
    width: 93%;
    margin: 0px auto;
    max-width: 1600px;
    min-width: 1200px;
    position: relative;
    display: flex;
    justify-content: center;

    @media (max-width: 1200px) {
        min-width: 100%;
        max-width: 100%;
        padding-inline: 15px;
        box-sizing: border-box;
    }
`;
export const Video = styled.iframe`
    width: 800px;
    height: 480px;
    max-width: 100%;
    outline: 0;
    background: white;
    border: 0;
    border-radius: 35px;
    border: 20px solid white;
    box-shadow: 0.351px 3.236px 26.99px -6.99px rgba(0, 0, 0, 0.4);
    @media (max-width: 575px) {
        height: 300px;
        border-width: 10px;
    }
`;

export const Partners = styled.div`
    width: 100%;
    margin-block-start: 75px;
    padding-block: 25px;
    display: flex;
    box-shadow: 2.939px 4.045px 17.01px 3.99px rgba(0, 0, 0, 0.11);
    box-sizing: border-box;

    ${Inner} {
        display: flex;
        gap: 25px;
        align-items: center;
        box-sizing: border-box;
    }
    @media (max-width: 720px) {
        padding-block: 15px;
        margin-block-start: 40px;
        padding-inline: 15px;
    }
`;
export const ImgItem = styled.img`
    height: auto;
    width: auto;
    max-width: 85px;
    @media (max-width: 720px) {
        width: 65px;
        max-width: calc((100% - 5 * 15px) / 6);
    }
`;

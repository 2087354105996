import React from 'react'
import * as S from './style'

const SectionWrapper = ({
    children,
    sectionRef,
    paddingBlock,
    bgColor,
    maxHeight,
    minHeight,
    boxShadow,
    isInnerIncluded = false,
}) => {
    const getInnerView = (children) => {
        return <S.Inner>{children}</S.Inner>
    }
    return (
        <S.Wrapper
            ref={sectionRef}
            paddingBlock={paddingBlock}
            bgColor={bgColor}
            maxHeight={maxHeight}
            minHeight={minHeight}
            boxShadow={boxShadow}
        >
            {isInnerIncluded ? getInnerView(children) : children}
        </S.Wrapper>
    )
}

export default SectionWrapper

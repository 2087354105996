import React, { useEffect, useLayoutEffect } from 'react'
import SectionWrapper from '../common/SectionWrapper'
import CircleBg from '../../images/screen-1-bg.png'
import TabletMockup from '../../images/screen-1/img-1.png'
import PhoneMockup from '../../images/screen-1/img-2.png'
import Food from '../../images/screen-1/img-3.png'
import { useAnimation } from 'framer-motion'
import { useSection } from '../../hooks'
import { useLabelsContext } from '../../context/LabelsContext'
import AOS from 'aos'
import * as S from './style'

const WelcomeSection = ({
    pagePaddinInline,
    sectionRef,
    onSizeChange,
    scrollToSection,
    onVisible,
}) => {
    const controls = useAnimation()
    const { WelcomeSection: labels } = useLabelsContext()
    const { setRefs } = useSection({ sectionRef, onVisible })

    const handleContactClick = () => {
        scrollToSection('contactUs')
    }

    useEffect(() => {
        AOS.init()
        AOS.refresh()
    }, [])

    useLayoutEffect(() => {
        onSizeChange(sectionRef.current.clientHeight)
    }, [])
    useEffect(() => {
        controls.start({
            rotate: [-10, 10],
            scale: [0.8, 0.85, 0.9, 0.95, 1, 1.1],
            transition: {
                duration: 1.8,
                repeat: Infinity,
                repeatType: 'reverse',
            },
        })
    }, [controls])

    return (
        <SectionWrapper
            sectionRef={setRefs}
            paddingBlock={''}
            pagePaddinInline={pagePaddinInline}
            minHeight={'500px'}
            maxHeight={'773px'}
        >
            <S.Background />
            <S.Inner>
                <S.ContentContainer>
                    <S.BackgroundImg src={CircleBg} />
                    <S.Content
                        data-aos="fade-up"
                        data-aos-duration="1000"
                        className={'textDirection'}
                    >
                        {labels.content}
                        <S.Button
                            onClick={() => handleContactClick()}
                            color={'white'}
                            bgcolor={'#161616'}
                            // data-aos="fade-left"
                            // data-aos-delay="300"
                            // data-aos-duration="1000"
                            animate={{
                                scale: [0.9, 1.1],
                            }}
                            transition={{
                                repeat: Infinity,
                                repeatType: 'reverse',
                                duration: 1.5,
                            }}
                        >
                            {labels.contactButton}
                        </S.Button>
                    </S.Content>
                </S.ContentContainer>
                <S.ImgsContainer>
                    <S.TabletMockup
                        initial={{
                            opacity: 0.5,
                            x: '500px',
                        }}
                        animate={{
                            opacity: 1,
                            x: '0',
                        }}
                        transition={{
                            duration: 1,
                        }}
                        src={TabletMockup}
                        className={'flipImg'}
                    />
                    <S.PhoneMockup
                        src={PhoneMockup}
                        initial={{ opacity: 0.5, y: '-1000px' }}
                        animate={{ opacity: 1, y: '0' }}
                        transition={{
                            duration: 1,
                        }}
                        className={'flipImg'}
                    />
                    <S.FoodImg
                        data-aos="fade-down"
                        src={Food}
                        animate={controls}
                        // transition-rotate={{ yoyo: Infinity }}
                    />
                </S.ImgsContainer>
            </S.Inner>
        </SectionWrapper>
    )
}

export default WelcomeSection

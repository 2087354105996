import React from 'react';
import { useSection, useContentDialog } from '../../hooks';
import { useLabelsContext } from '../../context/LabelsContext';
import { useSectionContentContext } from '../../context/SectionContentContext';
import { useInView } from 'framer-motion';
import KdsImg from '../../images/section-8/kds.png';
import KdsSmallImg from '../../images/section-8/kds_small.png';
import DesktopJoinNow from '../common/DesktopJoinNow';
import JoinNow from '../common/JoinNow';
import ReadMoreLink from '../common/ReadMoreLink/ReadMoreLink';
import * as S from './style';

const KdsSection = ({ sectionRef, onVisible, scrollToSection }) => {
    const { kdsSection: labels } = useLabelsContext();
    const { kds: kdsContent } = useSectionContentContext();
    const { ContentDialog, onOpenDialog } = useContentDialog({
        content: kdsContent?.content,
        scrollToSection,
    });
    const isInViewOnce = useInView(sectionRef, { once: true });
    const isInView = useInView(sectionRef);
    const { setRefs } = useSection({ sectionRef, onVisible });

    return (
        <S.Wrapper ref={setRefs} bgColor={'white'}>
            <S.Inner>
                <S.ImgContainer
                    style={{
                        transform: isInViewOnce
                            ? 'translateX(0)'
                            : 'translateX(-500px)',
                        opacity: isInViewOnce ? 1 : 0,
                        transition:
                            'all 0.5s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s',
                    }}
                >
                    <S.KdsImg
                        src={KdsImg}
                        style={{
                            transform: isInViewOnce
                                ? 'translateX(0)'
                                : 'translateX(-500px)',
                            opacity: isInViewOnce ? 1 : 0,
                            transition:
                                'all 0.5s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s',
                        }}
                    />
                    <S.KdsSmallImg
                        src={KdsSmallImg}
                        animate={{
                            // scale: [0.9, 1.05],
                            translateY: [0, -10, 0],
                        }}
                        transition={{
                            repeat: Infinity,
                            repeatType: 'mirror',
                            duration: 1,
                            delay: 1,
                        }}
                        // style={{
                        //     transform: isInView
                        //         ? 'translateY(0)'
                        //         : 'translateY(-500px)',
                        //     opacity: isInView ? 1 : 0,
                        //     transition:
                        //         'all 0.5s cubic-bezier(0.17, 0.55, 0.55, 1) 0.2s',
                        // }}
                    />
                </S.ImgContainer>
                <S.Content className={'textDirection'}>
                    <S.Text
                        style={{
                            transform: isInViewOnce
                                ? 'translateX(0)'
                                : 'translateX(500px)',
                            opacity: isInViewOnce ? 1 : 0,
                            transition:
                                'all 0.5s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s',
                        }}
                    >
                        <h2>{labels.title}</h2>
                        {labels.content}
                        <ReadMoreLink onClick={onOpenDialog} />
                        <JoinNow scrollToSection={scrollToSection} />
                    </S.Text>
                </S.Content>
            </S.Inner>
            <DesktopJoinNow scrollToSection={scrollToSection} />
            <ContentDialog />
        </S.Wrapper>
    );
};

export default KdsSection;

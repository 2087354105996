import React, { useState, useCallback, memo } from 'react';
import Header from '../../components/Header';
import StickyHeader from '../../components/StickyHeader';
import MenuTabSection from '../../components/MenuTabSection';
import WelcomeSection from '../../components/WelcomeSection';
import ContentSection from '../../components/ContentSection';
import KioskSection from '../../components/KioskSection';
import EcommerceSection from '../../components/EcommerceSection';
import AppSection from '../../components/AppSection';
import ContactSection from '../../components/ContactSection';
import VideoSection from '../../components/VideoSection';
import TabPosSection from '../../components/TabPosSection';
import KdsSection from '../../components/KdsSection';
import WaitersSection from '../../components/WaitersSection';
import ReceiptsSection from '../../components/ReceiptsSection';
import PaymentSection from '../../components/PaymentSection';
import Footer from '../../components/Footer';
import Dialog from '../../components/common/Dialog';
import '../../fonts/FbAbsoluti/FbAbsoluti.css';
import { useApp } from '../../hooks';
import 'aos/dist/aos.css';
import GlobalStyle from './globalStyle';
import * as S from './style';

function Main({ language, isMaxShopIO }) {
    const pagePaddinInline = '4.5%';
    const { refs, screenSize, scrollToSection } = useApp();
    const [firstSectionHeight, setFirstSectionHeight] = useState(0);
    const handleSizeChange = (value) => {
        setFirstSectionHeight(value);
    };
    const isRTL = language === 'heb' ? true : false;
    const [visibleSection, setVisibleSection] = useState('tech');

    const handleVisibleSection = useCallback((sectionId) => {
        setVisibleSection(sectionId);
    });

    return (
        <S.Wrapper isRTL={isRTL}>
            <GlobalStyle isRTL={isRTL} />
            <StickyHeader
                pagePaddinInline={pagePaddinInline}
                refs={refs}
                screenSize={screenSize}
                firstSectionHeight={firstSectionHeight}
                visibleSection={visibleSection}
            />
            <Header
                isMaxShopIO={isMaxShopIO}
                pagePaddinInline={pagePaddinInline}
                refs={refs}
                screenSize={screenSize}
                firstSectionHeight={firstSectionHeight}
                language={language}
                visibleSection={visibleSection}
            />
            <WelcomeSection
                isRTL={isRTL}
                sectionRef={refs.welcome}
                onVisible={() => handleVisibleSection('welcome')}
                pagePaddinInline={pagePaddinInline}
                onSizeChange={handleSizeChange}
                scrollToSection={scrollToSection}
            />
            <ContentSection
                sectionRef={refs.tech}
                scrollToSection={scrollToSection}
                onVisible={() => handleVisibleSection('tech')}
                pagePaddinInline={pagePaddinInline}
            />
            <KioskSection
                pagePaddinInline={pagePaddinInline}
                sectionRef={refs.maxKiosk}
                onVisible={() => handleVisibleSection('maxKiosk')}
                scrollToSection={scrollToSection}
            />
            <EcommerceSection
                pagePaddinInline={pagePaddinInline}
                sectionRef={refs.web}
                onVisible={() => handleVisibleSection('web')}
                scrollToSection={scrollToSection}
            />
            <AppSection
                pagePaddinInline={pagePaddinInline}
                sectionRef={refs.eApp}
                onVisible={() => handleVisibleSection('eApp')}
                scrollToSection={scrollToSection}
            />
            <MenuTabSection
                sectionRef={refs.maxMenuTab}
                onVisible={() => handleVisibleSection('maxMenuTab')}
                scrollToSection={scrollToSection}
            />
            <WaitersSection
                sectionRef={refs.iPadWaiters}
                scrollToSection={scrollToSection}
                isRTL={isRTL}
                onVisible={() => handleVisibleSection('iPadWaiters')}
            />
            <TabPosSection
                sectionRef={refs.tabPos}
                onVisible={() => handleVisibleSection('tabPos')}
                scrollToSection={scrollToSection}
            />
            <KdsSection
                sectionRef={refs.kds}
                scrollToSection={scrollToSection}
                onVisible={() => handleVisibleSection('kds')}
            />
            <ReceiptsSection
                sectionRef={refs.greenReceipts}
                scrollToSection={scrollToSection}
                onVisible={() => handleVisibleSection('greenReceipts')}
            />
            <PaymentSection
                sectionRef={refs.qr}
                onVisible={() => handleVisibleSection('qr')}
                scrollToSection={scrollToSection}
            />
            <VideoSection
                sectionRef={refs.contactUs}
                onVisible={() => handleVisibleSection('contactUs')}
                scrollToSection={scrollToSection}
            />
            <ContactSection
                scrollToSection={scrollToSection}
                pagePaddinInline={pagePaddinInline}
                onVisible={() => handleVisibleSection('contactUs')}
                sectionRef={refs.contactUs}
                language={language}
            />
            <Footer />
        </S.Wrapper>
    );
}

export default memo(Main);

import React from 'react'
import JoinNow from '../common/JoinNow'
import BgImg from '../../images/screen-6/bg.png'
import TabletImg from '../../images/screen-6/tablet.png'
import HandImg from '../../images/screen-6/hand.png'
import { useSection } from '../../hooks'
import { useLabelsContext } from '../../context/LabelsContext'
import { useInView } from 'framer-motion'
import * as S from './style'

const MenuTabSection = ({ sectionRef, scrollToSection, onVisible }) => {
    const { menuTabSection: labels } = useLabelsContext()
    const isInViewOnce = useInView(sectionRef, { once: true })
    const { setRefs } = useSection({ sectionRef, onVisible })

    return (
        <S.Wrapper ref={setRefs} bgColor={'#bebdc1'} img={BgImg}>
            <S.DarkLayer />
            <S.Inner>
                <S.TabletImg
                    src={TabletImg}
                    style={{
                        transform: isInViewOnce
                            ? 'translateX(0)'
                            : 'translateX(-500px)',
                        opacity: isInViewOnce ? 1 : 0,
                        transition:
                            'all 0.5s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s',
                    }}
                />
                <JoinNow scrollToSection={scrollToSection} />
                <S.Content>
                    <S.Text
                        className={'textDirection'}
                        style={{
                            transform: isInViewOnce
                                ? 'translateX(0)'
                                : 'translateX(500px)',
                            opacity: isInViewOnce ? 1 : 0,
                            transition:
                                'all 0.5s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s',
                        }}
                    >
                        <h2>{labels.title}</h2>
                        {labels.content}
                    </S.Text>
                </S.Content>
            </S.Inner>
            <S.HandImg
                src={HandImg}
                animate={{ y: [0, -20, 0], x: [0, 20, 0] }}
                transition={{
                    duration: 2,
                    repeat: Infinity,
                    repeatType: 'mirror',
                }}
            />
        </S.Wrapper>
    )
}

export default MenuTabSection

import styled from 'styled-components'

export const Wrapper = styled.div`
    box-sizing: border-box;
    width: 100%;
    background: white;
    padding-block: 10px;
    padding-inline: 15px;
    min-height: 40px;
    padding-inline: ${({ pagePaddinInline }) => pagePaddinInline};
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: black;
    font-size: 20px;
    @media (max-width: 440px) {
        width: 300px;
        margin: 0px auto;
    }
`

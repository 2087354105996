import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Wrapper = styled(motion.div)`
    box-sizing: border-box;
    width: 100%;
    background: rgb(73, 209, 81);
    background: linear-gradient(
        336deg,
        rgba(73, 209, 81, 1) 0%,
        rgba(104, 216, 97, 1) 25%,
        rgba(97, 216, 95, 1) 100%
    );
    min-height: 500px;
    padding-block-start: 1.5vw;
    margin-block-end: 4%;
    color: white;
    position: relative;
    @media (max-width: 1100px) {
        min-height: auto;
    }
    @media (max-width: 900px) {
        padding-block: 0 11px;
    }
    @media (max-width: 600px) {
        margin-block-end: 0;
    }
`;
export const Inner = styled(motion.div)`
    width: 93%;
    margin: 0px auto;
    max-width: 1600px;
    min-width: 1200px;
    height: fit-content;
    position: relative;
    display: flex;
    @media (max-width: 1200px) {
        min-width: 100%;
        max-width: 100%;
        padding-inline: 15px;
        box-sizing: border-box;
    }
    @media (max-width: 900px) {
        flex-direction: column;
        align-items: center;
        gap: 30px;
        padding-block-start: 30px;
    }
`;
export const WomenImgContainer = styled.div`
    width: 63%;
    margin-inline-start: -5.5%;
    height: fit-content;
    margin-bottom: -4px;
    position: relative;
    @media (max-width: 1100px) {
        position: relative;
        width: 40%;
        margin-inline-start: 0;
        right: 0 !important;
    }
    @media (max-width: 850px) {
        width: calc(100% - 270px);
    }

    @media (max-width: 600px) {
        width: 100%;
        min-width: 275px;
        gap: 8px;
        height: fit-content;
    }
`;
export const Content = styled.div`
    width: 41%;
    height: 400px;
    top: 0;
    position: absolute;
    right: 5%;
    font-size: 34px;
    margin-top: 2%;
    white-space: pre-wrap;
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5%;
    & h2 {
        margin: 0;
        font-weight: 900;
        font-size: 48px;
    }

    @media (max-width: 1500px) {
        width: 55%;
    }
    @media (max-width: 1200px) {
        font-size: 28px;

        & h2 {
            margin-bottom: 0;

            font-size: 36px;
        }
    }
    @media (max-width: 1100px) {
        position: relative;
        right: 0 !important;
        width: 100%;
        margin-left: 30%;
    }
    @media (max-width: 900px) {
        margin-left: unset;

        font-size: 28px;
        height: fit-content;
        gap: 15px;
        & h2 {
            font-size: 38px;
            /* text-align: center; */
        }
    }
    @media (max-width: 600px) {
        min-width: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        height: fit-content;
        margin-block-start: 0;
        text-align: center;
        & h2 {
            line-height: 100%;
        }
    }
`;
export const WomeImg = styled.img`
    width: 100%;
    margin-left: -5%;
    @media (max-width: 1500px) {
        margin-left: -35%;
    }
    @media (max-width: 1100px) {
        display: none;
    }
    @media (max-width: 900px) {
        display: block;
        position: absolute;
        left: -50px;
        bottom: 0;
        width: 49%;
        min-width: 426px;
    }
    @media (max-width: 535px) {
        left: -100px;
    }
`;
export const DeviceContainer = styled.div`
    width: 23.7%;
    min-width: 240px;
    margin-block-start: -14%;
    position: relative;
    min-height: 100%;
    @media (max-width: 1100px) {
        margin-inline-start: auto;
        margin-inline-end: 125px;
    }
    @media (max-width: 900px) {
        margin-inline-end: 0;
        margin: 0px auto;
    }
`;
export const DeviceImg = styled.img`
    width: 100%;
    position: absolute;
    @media (max-width: 900px) {
        position: relative;
    }
`;
export const HamburgerImg = styled(motion.img)`
    position: absolute;
    top: 0;
    margin-block-start: 9%;
    width: 79%;
    margin-inline-start: -59%;
    @media (max-width: 900px) {
        left: 0;
    }
`;
export const CokeImg = styled(motion.img)`
    width: 49%;
    position: absolute;
    bottom: 0;
    margin-inline-start: 105%;
    margin-block-end: 70%;
    @media (max-width: 900px) {
        right: 0;
        width: 94px;
        margin-inline-end: -94px;
    }
`;
export const SizesContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;

    @media (max-width: 850px) {
        max-width: 312px;
    }
    @media (max-width: 600px) {
        max-width: 265px;
        min-width: 265px;
    }
    @media (max-width: 560px) {
        width: 100%;
        gap: 15px;
        flex-wrap: wrap;
        margin: 0px auto;
    }
`;

export const SizesItem = styled.div`
    width: 65px;
    height: 65px;
    border-radius: 50%;
    background: white;
    font-size: 40px;
    font-weight: 900;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 740px) {
        width: 55px;
        height: 55px;
        font-size: 28px;
    }
`;

import React, { useCallback, useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify'

const useLogin = (FormSchema) => {
    const [isDirty, setIsDirty] = useState(false)
    const [isSending, setIsSending] = useState(false)
    const [isFormSent, setIsFormSent] = useState(false)
    const getFormInit = useCallback(() => {
        let formFields = {}
        FormSchema.forEach((field) => {
            formFields[field.name] = {
                ...field,
                value: field.value ?? '',
                isValid: true,
                isRequired: field.isRequired,
            }
        })
        return formFields
    }, [FormSchema])
    const [formFields, setFormFields] = useState(getFormInit())
    useEffect(() => setFormFields(getFormInit()), [])

    const handleSubmit = async (e) => {
        // setIsDirty(true)
        // setIsSending(true)
        if (validateForm()) {
            return true
        } else {
            e.preventDefault()
            // notify('Oops, there are invalid fields 😟!')
            // setIsSending(false)
            return false
        }
    }
    const handleFieldChange = (fieldName, value) => {
        setFormFields((state) => ({
            ...state,
            [fieldName]: { ...state[fieldName], value: value },
        }))

        if (isDirty) {
            validateForm(fieldName, value)
        }
    }

    const hadnleFieldValidation = (fieldName, isValid) => {
        setFormFields((state) => ({
            ...state,
            [fieldName]: { ...state[fieldName], isValid: isValid },
        }))
    }

    function validateForm(field, value = null) {
        setIsDirty(true)
        let countet = 0
        const fieldsKeys = field ? [field] : Object.keys(formFields)
        fieldsKeys.forEach((fieldKey) => {
            const newValue = value || formFields[fieldKey].value
            if (formFields[fieldKey].isRequired && newValue.length === 0) {
                hadnleFieldValidation(fieldKey, false)
                countet++
                return
            } else if (newValue.length > 0) {
                if (
                    formFields[fieldKey].type === 'tel' &&
                    !PHONE_REGEX.test(newValue)
                ) {
                    hadnleFieldValidation(fieldKey, false)
                    return
                }
            }
            hadnleFieldValidation(fieldKey, true)
        })

        return countet === 0 ? true : false
    }
    return {
        handleSubmit,
        formFields,
        handleFieldChange,
        isFormSent,
        isDirty,
        isSending,
    }
}

export default useLogin

const PHONE_REGEX = /^0(5[^7]|[2-4]|[8-9]|7[0-9])[0-9]{7}$/

import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Wrapper = styled.div`
    box-sizing: border-box;
    width: 100%;
    min-height: 655px;
    color: black;
    /* padding-block: 5vw 1vw; */
    background: ${({ bgColor }) => bgColor};
    position: relative;
    display: flex;
    box-shadow: 2.939px 4.045px 39.69px 9.31px rgba(0, 0, 0, 0.11);
    margin-block-start: 7%;
    @media (max-width: 1100px) {
        min-height: unset;
    }

    @media (max-width: 900px) {
        height: auto;
        padding-block-start: 35px;
    }
    @media (max-width: 600px) {
    }
`;

export const Inner = styled.div`
    width: 93%;
    margin: 0px auto;
    max-width: 1600px;
    min-width: 1200px;
    direction: ltr;
    position: relative;
    justify-content: center;
    display: flex;

    @media (max-width: 1200px) {
        min-width: 100%;
        max-width: 100%;
        padding-inline: 15px;
        gap: 25px;
        box-sizing: border-box;
    }
    @media (max-width: 900px) {
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;
        gap: 20px;
        /* flex-direction: column; */
    }
`;

export const Content = styled.div`
    position: relative;
    width: 40%;
    height: fit-content;
    min-height: 100%;
    display: flex;
    align-items: center;

    @media (max-width: 1100px) {
        width: 450px;
        max-width: 95%;
        margin: 0px auto;
    }
    @media (max-width: 900px) {
        min-width: 80%;
        min-height: fit-content;
        justify-content: center;
        & h2 {
            text-align: center;
        }
    }
`;
export const Text = styled(motion.div)`
    font-size: 34px;
    color: black;
    max-width: 550px;
    display: flex;
    gap: 10px;
    flex-direction: column;
    white-space: pre-wrap;
    & h2 {
        margin: 0;
        font-size: 48px;
        color: black;
    }
    @media (max-width: 1200px) {
        font-size: 28px;

        & h2 {
            font-size: 40px;
        }
    }
`;

export const ImgContainer = styled.div`
    width: 43.8%;
    position: relative;
    @media (max-width: 900px) {
        width: 90%;

        min-width: 320px;
        display: flex;
        justify-content: center;
        gap: 15px;
        flex-direction: row-reverse;
        align-items: center;
    }
`;

export const WomanImg = styled(motion.img)`
    position: relative;
    width: 100%;
    top: 0;
    margin-inline-start: auto;
    display: block;
    margin-inline-end: 8%;
    /* min-width: 465px; */
    margin-top: -12%;

    @media (max-width: 1200px) {
        min-width: 320px;
        margin-top: 0;
    }
    @media (max-width: 900px) {
        min-width: 300px;
        width: 300px;
        margin-inline: 0;
    }
`;

export const CoffeeImg = styled(motion.img)`
    position: absolute;
    width: 44%;
    top: -8%;
    right: 0%;

    @media (max-width: 1000px) {
        top: -5%;
        width: 32%;
    }
    @media (max-width: 900px) {
        display: none;
    }
`;
export const PrinterImgMobile = styled(motion.img)`
    position: relative;
    width: 180px;
    height: auto;
    display: none;
    @media (max-width: 900px) {
        display: block;
    }
`;

import { useCallback, useEffect } from 'react'
import { useInView } from 'react-intersection-observer'

const useSection = ({ sectionRef, onVisible }) => {
    const { ref: inViewRef, inView } = useInView({ threshold: 0.5 })

    const setRefs = useCallback(
        (node) => {
            sectionRef.current = node
            inViewRef(node)
        },
        [inViewRef]
    )

    useEffect(() => {
        if (inView) {
            onVisible()
        }
    }, [inView])

    return { setRefs }
}

export default useSection

import React from 'react';
import { useSection, useContentDialog } from '../../hooks';
import { useLabelsContext } from '../../context/LabelsContext';
import { useSectionContentContext } from '../../context/SectionContentContext';
import BgImg from '../../images/section-11/background.png';
import QrImg from '../../images/section-11/paymentImg.png';
import * as S from './style';
import DesktopJoinNow from '../common/DesktopJoinNow';
import JoinNow from '../common/JoinNow';
import ReadMoreLink from '../common/ReadMoreLink';

const PaymentSection = ({ sectionRef, onVisible, scrollToSection }) => {
    const { qr } = useSectionContentContext();
    const { ContentDialog, onOpenDialog } = useContentDialog({
        content: qr?.content,
        scrollToSection,
    });
    const { setRefs } = useSection({ sectionRef, onVisible });
    const { paymentSection: labels } = useLabelsContext();

    return (
        <S.Wrapper ref={setRefs} bgImg={BgImg}>
            <S.Inner>
                <S.Content>
                    <S.Text className={'textDirection'}>
                        <h2>{labels.title}</h2>
                        {labels.content}
                        <ReadMoreLink onClick={onOpenDialog} />
                        <JoinNow scrollToSection={scrollToSection} />
                    </S.Text>
                </S.Content>
                <S.ImgContainer>
                    <S.Img src={QrImg} />
                </S.ImgContainer>
            </S.Inner>
            <DesktopJoinNow scrollToSection={scrollToSection} />
            <ContentDialog />
        </S.Wrapper>
    );
};

export default PaymentSection;

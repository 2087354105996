import React, { createContext, useContext, useMemo } from 'react';

const SectionContentContext = createContext();

const SectionContentContextProvider = ({ children, mappedContent }) => {
    return (
        <SectionContentContext.Provider value={mappedContent}>
            {children}
        </SectionContentContext.Provider>
    );
};

const useSectionContentContext = () => {
    const context = useContext(SectionContentContext);
    if (!context) {
        throw new Error('Labels Context Error');
    }
    return context;
};

export { SectionContentContextProvider, useSectionContentContext };

import styled from 'styled-components'
import { motion } from 'framer-motion'

export const Wrapper = styled.div`
    box-sizing: border-box;
    width: 100%;
    background: #bebdc1;
    min-height: 655px;
    color: white;
    padding-block: 5vw;
    background: url(${({ img }) => img}) no-repeat;
    background-size: cover;
    position: relative;
    background-position: left center;
    display: flex;

    @media (max-width: 1100px) {
        min-height: 465px;
    }

    @media (max-width: 600px) {
        padding-block: 35px;
    }
`

export const DarkLayer = styled.div`
    background: black;
    opacity: 0.5;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
`

export const Inner = styled.div`
    width: 93%;
    margin: 0px auto;
    max-width: 1600px;
    min-width: 1200px;
    direction: ltr;
    position: relative;
    display: flex;
    @media (max-width: 1200px) {
        min-width: 100%;
        max-width: 100%;
        padding-inline: 15px;
        box-sizing: border-box;
    }
    @media (max-width: 900px) {
        flex-direction: column-reverse;
        gap: 10px;
    }
`

export const Content = styled.div`
    position: relative;
    width: 38%;
    height: fit-content;
    margin-inline-start: auto;
    min-height: 100%;
    margin-block-start: auto;
    display: flex;
    align-items: center;
    @media (max-width: 900px) {
        min-height: fit-content;
        min-width: 80%;
        margin: 0px auto;

        & h2 {
            text-align: center;
        }
    }
    @media (max-width: 550px) {
        min-width: 95%;
    }
`

export const Text = styled(motion.div)`
    font-size: 34px;
    color: white;
    max-width: 550px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    white-space: pre-wrap;
    & h2 {
        margin: 0;
        font-size: 48px;
    }
    @media (max-width: 1200px) {
        font-size: 28px;

        & h2 {
            font-size: 40px;
        }
    }
    @media (max-width: 900px) {
        margin: 0px auto;
    }
`

export const TabletImg = styled(motion.img)`
    position: absolute;
    width: 45%;
    left: 10%;
    top: 0;
    margin-block-start: -5%;
    z-index: 9;
    @media (max-width: 1630px) {
        width: 48%;
    }
    @media (max-width: 1500px) {
        width: 52%;
    }
    @media (max-width: 900px) {
        position: relative;
        min-width: 304px;
        margin-block-start: 15px;
    }
`
export const HandImg = styled(motion.img)`
    position: absolute;
    width: 31%;
    bottom: -15vw;
    left: -5%;
    z-index: 9;
    @media (max-width: 1100px) {
        width: 36%;
        bottom: -19vw;
        min-width: 214px;
    }
`

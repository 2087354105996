import styled from 'styled-components';

export const Wrapper = styled.div`
    box-sizing: border-box;
    position: relative;
    width: 100%;
    background: ${({ bgColor }) => bgColor ?? 'white'};
    min-height: 800px;
    color: white;
    padding-block: 9.5% 12%;
    min-height: 500px;
    @media (max-width: 740px) {
        padding-block: 105px 35px;
    }
`;

export const Inner = styled.div`
    width: 93%;
    margin: 0px auto;
    max-width: 1600px;
    position: relative;
    display: flex;
    flex-direction: column;

    @media (max-width: 1200px) {
        min-width: 100%;
        max-width: 100%;
        padding-inline: 15px;
        box-sizing: border-box;
    }
`;

export const Content = styled.div`
    width: 100%;
    display: flex;
    align-items: center;

    @media (max-width: 740px) {
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
    }
`;
export const UpgradeSalesImg = styled.img`
    width: 42%;
    @media (max-width: 740px) {
        width: 100%;
        min-width: 300px;
        max-width: 480px;
    }
`;

export const Text = styled.div`
    color: black;
    font-size: 36px;
    margin-top: -2vw;

    & h1 {
        font-size: 41px;
        font-weight: 700;
        margin: 0;
        margin-block-end: 0.67em;
    }

    & p {
        max-width: 660px;
        margin: 0;
    }

    @media (max-width: 1200px) {
        & h1 {
            font-size: 34px;
        }
        & p {
            font-size: 28px;
        }
    }
    @media (max-width: 740px) {
        & h1 {
            font-size: 28px;
            margin-bottom: 10px;
        }
        & p {
            font-size: 24px;
            max-width: 380px;
        }
        margin-block-end: 20px;
        text-align: center;
    }
`;

export const List = styled.ul`
    width: 100%;
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 0;
    margin-block: 10vh;
    gap: 2vw;
    align-items: flex-start;
    @media (max-width: 1200px) {
        margin-block: 35px 55px;
        flex-wrap: wrap;
        gap: 25px 15px;
        & li {
            flex-basis: calc((100% - 15px) / 2);
        }
    }

    @media (max-width: 740px) {
        & li {
            flex-basis: 100%;
        }
        margin-block: 25px 35px;
    }
`;
export const Item = styled.li`
    color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    & span {
        display: block;
        font-size: 34px;
        font-weight: 700;
        margin-block-start: 15px;
    }
    & p {
        display: block;
        font-size: 31px;
        text-align: center;
        margin: 0;
        margin-block-start: 15px;
        white-space: pre-wrap;
    }

    @media (max-width: 1200px) {
        & span {
            font-size: 31px;
        }
        & p {
            font-size: 28px;
        }
    }

    @media (max-width: 740px) {
        & span {
            font-size: 25px;
            margin-block-start: 10px;
        }
        & p {
            font-size: 22px;
            margin-block-start: 5px;
            max-width: 320px;
        }
    }
`;
export const Icon = styled.img`
    width: 150px;
`;
export const IconContainer = styled.div`
    height: 100px;
    display: flex;
    align-items: center;
`;
export const JoinUs = styled.div`
    background: black;
    color: white;
    padding-block: 30px;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    font-size: 34px;
    font-weight: 900;
    position: relative;
    z-index: 9;
    @media (max-width: 555px) {
        /* flex-direction: column; */
        font-size: 26px;
    }
`;
export const Button = styled.button`
    background: #00ca2b;
    border-radius: 35px;
    font-size: 34px;
    font-weight: 900;
    min-width: 300px;
    color: white;
    padding: 11px 25px;
    border: 0;
    outline: 0;
    transition: all 0.3s ease-out;
    cursor: pointer;

    &:hover {
        background: white;
        color: black;
    }

    @media (max-width: 555px) {
        font-size: 26px;
        min-width: fit-content;
    }
`;
